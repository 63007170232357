/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getserviceMasterDataForUpdate, serviceMasterUpdate } from '../../common/api-service';

function EditServiceMaster() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const service_master_status = ['active', 'disabled'];

  const [AdminNotes, setAdminNotes] = useState('');
  const [serviceMasterDiscription, setServiceMasterDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [serviceMasterstatus, setServiceMasterStatus] = React.useState(' ');
  const [idget, setIdget] = React.useState(' ');
  const [masterIcon, setIcon] = useState('');
  const [data, setData] = useState([]);
  const [baseImage, setBaseImage] = useState('');

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const slice = base64.slice(22);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeImage = (baseImage) => {
    setBaseImage('');
  };

  useEffect(() => {
    // fake fetch data
    setBaseImage(baseImage);
  }, []);

  const getServiceMasterID = () => {
    const getServiceMasterID = localStorage.getItem('getServiceMasterID');
    setIdget(getServiceMasterID);
    const payload = {
      _id: getServiceMasterID,
    };
    return getserviceMasterDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.title);
        setServiceMasterDiscription(data.description);
        setAdminNotes(data.admin_notes);
        setServiceMasterStatus(data.status);
        setBaseImage(data.icon);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    getServiceMasterID();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      _id: idget,
      title: userName,
      description: serviceMasterDiscription,
      admin_notes: AdminNotes,
      status: serviceMasterstatus,
      icon: baseImage,
    };
    return serviceMasterUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/serviceMaster');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Update Service
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Title */}
                  <Grid>
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Discription */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Discription"
                      variant="outlined"
                      value={serviceMasterDiscription}
                      onChange={(e) => setServiceMasterDiscription(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Admin Notes */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={serviceMasterstatus}
                        onChange={(e) => {
                          setServiceMasterStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {service_master_status.map((service_master_status) => (
                          <MenuItem
                            key={service_master_status}
                            value={service_master_status}
                            style={(service_master_status, serviceMasterstatus, theme)}
                          >
                            {service_master_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography component="div" display="flex" justifyContent="center" alignItems="center">
                        Icon
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <br />
                      <img src={baseImage} alt="" height="200px" />
                      <IconButton sx={{ color: 'red' }} onClick={() => removeImage(baseImage)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Service
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
            {/* </form> */}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditServiceMaster;
