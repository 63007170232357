/* eslint-disable */
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import ServiceMaster from './pages/ServiceMaster/ServiceMaster';
import DashboardApp from './pages/DashboardApp';
import AddServiceMaster from './pages/ServiceMaster/AddServiceMaster';
import EditServiceMaster from './pages/ServiceMaster/EditServiceMaster';
import Coupons from './pages/Coupuns/Coupons';
import AddCoupons from './pages/Coupuns/AddCoupons';
import BulkUpload from './pages/BulkUpload/BulkUpload';
import BulkUploadDetails from './pages/BulkUpload/BulkUploadDetails';
import EditBulkUpload from './pages/BulkUpload/EditBulkUpload';
import GroomingTimeSlot from './pages/PetGrooming/GroomingTimeSlot/GroomingTimeSlot';
import GroomingTimeSlotDetails from './pages/PetGrooming/GroomingTimeSlot/GroomingTimeSlotDetails';
import GroomingBookingHistory from './pages/PetGrooming/GroomingBookingHistory/GroomingBookingHistory';
import BlogManagement from './pages/BlogManagement/BlogManagement';
import AddBlogManagement from './pages/BlogManagement/AddBlogManagement';
import EditBlogManagement from './pages/BlogManagement/EditBlogManagement';
import SitterAllocation from './pages/SitterAllocation/SitterAllocation';
import Banner from './pages/Banner/Banner';
import AddBanner from './pages/Banner/AddBanner';
import EditBanner from './pages/Banner/EditBanner';
import AllocationPage from './pages/SitterAllocation/AllocationPage';
import Request from './pages/Request/Request';
import PetList from './pages/PetList/PetList';
import UserManagement from './pages/UserManagement/UserManagement';
import Faqs from './pages/Faqs/Faqs';
import AddFaqs from './pages/Faqs/AddFaqs';
import EditFaqs from './pages/Faqs/EditFaqs';
import AddSitter from './pages/BulkUpload/AddSitter';
import EventCalender from './pages/SitterAllocation/EventCalender';
import GroomingBookingOngoing from './pages/PetGrooming/GroomingBookingHistory/GroomingBookingOngoing';
import GroomingBookingCompleted from './pages/PetGrooming/GroomingBookingHistory/GroomingBookingCompleted';
import GroomingBookingUpcoming from './pages/PetGrooming/GroomingBookingHistory/GroomingBookingUpcoming';
import PetGrooming from './pages/PetGrooming/PetGrooming';
import AddPetGrooming from './pages/PetGrooming/AddPetGrooming';
import EditPetGrooming from './pages/PetGrooming/EditPetGrooming';
import GroomingOffering from './pages/PetGrooming/GroomingOffering/GroomingOffering';
import GroomingAddOffering from './pages/PetGrooming/GroomingOffering/GroomingAddOffering';
import GroomingEditOffering from './pages/PetGrooming/GroomingOffering/GroomingEditOffering';
import GroomingAddons from './pages/PetGrooming/GroomingAddOns.js/GroomingOffering/GroomingAddons';
import GroomingAddAddons from './pages/PetGrooming/GroomingAddOns.js/GroomingOffering/GroomingAddAddons';
import GroomingEditAddons from './pages/PetGrooming/GroomingAddOns.js/GroomingOffering/GroomingEditAddons';
import GroomingSitterAllocation from './pages/PetGrooming/GroomingSitterAllocation/GroomingSitterAllocation';
import GroomingAllocationPage from './pages/PetGrooming/GroomingSitterAllocation/GroomingAllocationPage';
import GroomingEventCalender from './pages/PetGrooming/GroomingSitterAllocation/GroomingEventCalender';
import GroomingCancellation from './pages/PetGrooming/GroomingCancellation/GroomingCancellation';
import GroomingCancellationHistory from './pages/PetGrooming/GroomingCancellation/GroomingCancellationHistory';
import GroomingReschduleBooking from './pages/PetGrooming/GroomingReschduleBooking/GroomingReschduleBooking';
import GroomingReschduleHistory from './pages/PetGrooming/GroomingReschduleBooking/GroomingReschduleHistory';
import TopPetCare from './pages/TopPetCare';
import ContactUS from './pages/ContactUs';
import SitterContactUS from './pages/SitterContactUs';
import DogTraining from './pages/DogTraining/DogTraining';
import AddDogTraining from './pages/DogTraining/AddDogTraining';
import TrainingLesson from './pages/DogTraining/TrainingLesson/TrainingLesson';
import AddTrainingLesson from './pages/DogTraining/TrainingLesson/AddTrainingLesson';
import TrainingTimeSlotDetails from './pages/DogTraining/TrainingTimeSlot/TrainingTimeSlotDetails';
import TrainingTimeSlot from './pages/DogTraining/TrainingTimeSlot/TrainingTimeSlot';
import TrainingSubLesson from './pages/DogTraining/TrainingSubLesson/TrainingSubLesson';
import AddTrainingSubLesson from './pages/DogTraining/TrainingSubLesson/AddTrainingSubLesson';
import EditTrainingSubLesson from './pages/DogTraining/TrainingSubLesson/EditTrainingSubLesson';
import EditTrainingLesson from './pages/DogTraining/TrainingLesson/EditTrainingLesson';
import EditDogTraining from './pages/DogTraining/EditDogTraining';
import TrainingAllocationPage from './pages/DogTraining/TrainingSitterrAllocation/TrainingAllocationPage';
import TrainingEventCalender from './pages/DogTraining/TrainingSitterrAllocation/TrainingEventCalender';
import TrainingSitterAllocation from './pages/DogTraining/TrainingSitterrAllocation/TrainingSitterAllocation';
import TrainingCancellation from './pages/DogTraining/TrainingCancellation/TrainingCancellation';
import TrainingBookingHistory from './pages/DogTraining/TrainingBookingHistory/TrainingBookingHistory';
import TrainingBookingCompleted from './pages/DogTraining/TrainingBookingHistory/TrainingBookingCompleted';
import TrainingBookingOngoing from './pages/DogTraining/TrainingBookingHistory/TrainingBookingOngoing';
import TrainingBookingUpcoming from './pages/DogTraining/TrainingBookingHistory/TrainingBookingUpcoming';
import TrainingCancellationHistory from './pages/DogTraining/TrainingCancellation/TrainingCancellationHistory';
import TrainingReschduleBooking from './pages/DogTraining/TrainingReschduleBooking/TrainingReschduleBooking';
import TrainingReschduleHistory from './pages/DogTraining/TrainingReschduleBooking/TrainingReschduleHistory';
import GroomingSubscription from './pages/PetGrooming/GroomingSubscription';
import EditCoupons from './pages/Coupuns/EditCoupons';
import PetBoarding from './pages/Enquiry/PetBoarding';
import DogWalking from './pages/Enquiry/DogWalking';
import PetSitting from './pages/Enquiry/PetSitting';
import DogTrainings from './pages/Enquiry/DogTrainings';
import AddNotification from './pages/Notification/AddNotification'
import NotificationList from './pages/Notification/NotificationList';
import EditNotification from './pages/Notification/EditNotification';
import TimeSlots from './pages/TimeSlots';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'serviceMaster', element: <ServiceMaster /> },
        { path: 'addServiceMaster', element: <AddServiceMaster /> },
        { path: 'editServiceMaster', element: <EditServiceMaster /> },
        { path: 'coupons', element: <Coupons /> },
        { path: 'add_coupons', element: <AddCoupons /> },
        { path: 'edit_coupons', element: <EditCoupons /> },
        { path: 'blog', element: <Blog /> },
        { path: 'bulk_upload', element: <BulkUpload /> },
        { path: 'bulk_upload_details', element: <BulkUploadDetails /> },
        { path: 'editBulkUpload', element: <EditBulkUpload /> },
        { path: 'groomingTimeSlot', element: <GroomingTimeSlot /> },
        { path: 'groomingTimeSlotDetails', element: <GroomingTimeSlotDetails /> },
        { path: 'groomingBookingHistory', element: <GroomingBookingHistory /> },
        { path: 'groomingBookingCompleted', element: <GroomingBookingCompleted /> },
        { path: 'groomingBookingOngoing', element: <GroomingBookingOngoing /> },
        { path: 'groomingBookingUpcoming', element: <GroomingBookingUpcoming /> },
        { path: 'blogManagement', element: <BlogManagement /> },
        { path: 'add_blog', element: <AddBlogManagement /> },
        { path: 'editBlogs', element: <EditBlogManagement /> },
        { path: 'sitterAllocation', element: <SitterAllocation /> },
        { path: 'allocateSitter', element: <AllocationPage /> },
        { path: 'banner', element: <Banner /> },
        { path: 'addBanner', element: <AddBanner /> },
        { path: 'editBanner', element: <EditBanner /> },
        { path: 'request', element: <Request /> },
        { path: 'petList', element: <PetList /> },
        { path: 'userManagement', element: <UserManagement /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'addFaqs', element: <AddFaqs /> },
        { path: 'editFaqs', element: <EditFaqs /> },
        { path: 'addSitter', element: <AddSitter /> },
        { path: 'calander', element: <EventCalender /> },

        // Pet Grooming

        { path: 'petGrooming', element: <PetGrooming /> },
        { path: 'addPetGrooming', element: <AddPetGrooming /> },
        { path: 'groomingOffering', element: <GroomingOffering /> },
        { path: 'addGroomingOffering', element: <GroomingAddOffering /> },
        { path: 'editGroomingOffering', element: <GroomingEditOffering /> },
        { path: 'editPetGrooming', element: <EditPetGrooming /> },
        { path: 'groomingAddons', element: <GroomingAddons /> },
        { path: 'addGroomingAddons', element: <GroomingAddAddons /> },
        { path: 'editGroomingAddons', element: <GroomingEditAddons /> },
        { path: 'groomingSitterAllocation', element: <GroomingSitterAllocation /> },
        { path: 'groomingAllocationPage', element: <GroomingAllocationPage /> },
        { path: 'groomingCalander', element: <GroomingEventCalender /> },
        { path: 'groomingCancellation', element: <GroomingCancellation /> },
        { path: 'groomingCancellationHistory', element: <GroomingCancellationHistory /> },
        { path: 'groomingReschduleBooking', element: <GroomingReschduleBooking /> },
        { path: 'groomingReschduleHistory', element: <GroomingReschduleHistory /> },
        { path: 'groomingSubscription', element: <GroomingSubscription /> },

        // Dog Training

        { path: 'dogTraining', element: <DogTraining /> },
        { path: 'addDogTraining', element: <AddDogTraining /> },
        { path: 'editDogTraining', element: <EditDogTraining /> },
        { path: 'topPetCare', element: <TopPetCare /> },
        { path: 'contactUs', element: <ContactUS /> },
        { path: 'sitterContactUs', element: <SitterContactUS /> },
        { path: 'trainingCancellation', element: <TrainingCancellation /> },
        { path: 'trainingBookingHistory', element: <TrainingBookingHistory /> },
        { path: 'trainingBookingCompleted', element: <TrainingBookingCompleted /> },
        { path: 'trainingBookingOngoing', element: <TrainingBookingOngoing /> },
        { path: 'trainingBookingUpcoming', element: <TrainingBookingUpcoming /> },
        { path: 'trainingCancellationHistory', element: <TrainingCancellationHistory /> },
        { path: 'trainingReschduleBooking', element: <TrainingReschduleBooking /> },
        { path: 'trainingReschduleHistory', element: <TrainingReschduleHistory /> },


        // Training Lesson

        { path: 'trainingLesson', element: <TrainingLesson /> },
        { path: 'addTrainingLesson', element: <AddTrainingLesson /> },
        { path: 'editTrainingLesson', element: <EditTrainingLesson /> },

        // Training Time Slot

        { path: 'trainingTimeSlotDetails', element: <TrainingTimeSlotDetails /> },
        { path: 'addTrainingTimeAlots', element: <TrainingTimeSlot /> },
        // { path: 'addTimeSlot', element: <TimeSlots /> },

        // Training Sub Lesson

        { path: 'trainingSubLesson', element: <TrainingSubLesson /> },
        { path: 'addTrainingSubLesson', element: <AddTrainingSubLesson /> },
        { path: 'editTrainingSubLesson', element: <EditTrainingSubLesson /> },

        // Training Sitter Allocation
        { path: 'trainingAllocationPage', element: <TrainingAllocationPage /> },
        { path: 'trainingCalander', element: <TrainingEventCalender /> },
        { path: 'trainingSitterAllocation', element: <TrainingSitterAllocation /> },


        { path: 'notification', element: <AddNotification/> },
        { path: 'notificationlist', element: <NotificationList/> },
        { path: 'editnotification', element: <EditNotification/> },

        // Enquiry

        { path: 'petboarding', element: <PetBoarding /> },
        { path: 'dogwalking', element: <DogWalking /> },
        { path: 'petsetting', element: <PetSitting /> },
        { path: 'dogtrainings', element: <DogTrainings /> },


        // { path: 'test', element: <Test /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'dashboard', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
