/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Stack, Link, TextareaAutosize, Button , Modal} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';

import {
  addCoupons,
  addNotification,
  dropDownServiceMaster,
  getNotificationById,
  notificationUpdateById,
} from '../../common/api-service';
import Icon from '../../components/Iconify';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 3),
    textAlign: 'center',
    borderRadius: '15px'
  },
  img: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
  },
}));
function EditNotification() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [notificationDiscription, setNotificationDiscription] = useState('');
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState('');
  console.log('icon', icon);
  const [userName, setUserName] = useState('');
  const [notification, setNotificationfor] = useState('');

  useEffect(() => {
    getCouponID();
  }, []);

  const getCouponID = () => {
    const getCouponID = localStorage.getItem('setMasterId');
    // setIdget(getCouponID);

    const payload = {
      _id: getCouponID,
    };

    return getNotificationById(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;

        setUserName(data.title);
        setNotificationDiscription(data.description);
        setSendTo(data.notification_for);
        setSelectedFile(data.icon);
        setImageData(data.icon);
      } else {
        console.log('error');
      }
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Use this effect to update isActive based on couponStatus
  const [sendTo, setSendTo] = useState('');
  const [imageData, setImageData] = useState('');
  const [isImageDataChanged, setIsImageDataChanged] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  console.log('selectedFile', selectedFile);
  console.log('sendTo', sendTo);
  const userTypes = ['User', 'Sitter', 'Both'];

  const handle_title = (e) => {
    e.target.value = e.target.value.trimStart().replace(/[^a-zA-Z@./;:-\s]/g, '');
    setUserName(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('file', file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageData(reader.result); // Set Base64-encoded image data
        setIsImageDataChanged(true);
      };

      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const onSubmit = () => {
    console.log('gvhfvjbhv');
    const getCouponID = localStorage.getItem('setMasterId');
    setLoading(true);
    const payload = {
      _id: getCouponID,
      title: userName?.trim(),
      description: notificationDiscription,
      notification_for: sendTo,
      icon: isImageDataChanged ? imageData : '',
    };
    return notificationUpdateById(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/notificationlist');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageClick = () => {
    handleOpen(); // Open the modal when the image is clicked
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '15px' }}>
        <Icon icon="gg:add" style={{ fontSize: '1.5em' }} /> {/* Adjust the fontSize as needed */}
        <Typography
          variant="h4"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="start"
          alignItems="center"
          marginLeft="8px" // Adjust the margin as needed
        >
          Edit Notification
        </Typography>
      </div>

      <Box
        component="form"
        // sx={{
        //   '& > :not(style)': { m: 1, width: '50ch' },
        // }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <form > */}
        <Stack spacing={3} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={6} lg={6}>
              <div className="form-group">
                <div className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    value={userName}
                    onChange={(e) => handle_title(e)}
                    style={{ width: '100%' }}
                    readonly
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid xs={6} lg={8}>
                  <div className="form-group">
                    <div
                      className="form-control"
                      style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}
                    >
                      <TextField
                        variant="outlined"
                        value={selectedFile ? selectedFile.name : selectedFile}
                        label="Choose File"
                        // value={icon}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <label htmlFor="file-upload">
                                <input
                                  accept=".jpg, .png, .jpeg"
                                  id="file-upload"
                                  type="file"
                                  onChange={handleFileChange}
                                  style={{ display: 'none' }}
                                />
                                <Button variant="contained" component="span">
                                  Browse
                                </Button>
                              </label>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={6} lg={3} style={{ marginLeft: '10px', justifyContent: 'center' }}>
                  <div style={{ backgroundColor: '#08273d', padding: '5px', borderRadius: '5px' }}>
                    <div style={{ backgroundColor: '#9fe0c4', padding: '2px', borderRadius: '5px' }}
                    className={classes.imgContainer}
                    onClick={handleImageClick}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleImageClick();
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    >
                      {
                        <img
                          src={imageData}
                          alt="img"
                          height={80}
                          width={120}
                          style={{cursor:'pointer'}}
                        />
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* Discription */}
            <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    multiline
                    // rows={2} // Set the number of rows you want to display
                    placeholder="Description"
                    value={notificationDiscription}
                    onChange={(e) => setNotificationDiscription(e.target.value)}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </Grid>

            {/* minimum purchase */}
            <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="user-type-label">Send To</InputLabel>
                    <Select
                      labelId="user-type-label"
                      id="user-type"
                      value={sendTo}
                      onChange={(e) => setSendTo(e.target.value)}
                      label="Send To"
                    >
                      {userTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>

            {/* Coupon validity in days */}
          </Grid>

          <Button
            variant="contained"
            type="submit"
            style={{ width: '200px', textAlign: 'end', marginLeft: 'auto', marginTop: '50px' }}
          >
            Update Notification
          </Button>
        </Stack>
      </Box>

      <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
        >
          <div className={classes.paper}>
            <img
              src={imageData}
              alt="img"
              className={classes.img}
            />
          </div>
        </Modal>
    </div>
  );
}

export default EditNotification;
