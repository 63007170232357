/* eslint-disable */
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import swal from 'sweetalert';
import Switch from '@mui/material/Switch';
// component
import Iconify from '../../../components/Iconify';
import { GrommingTopPetCare } from '../../../common/api-service';

// ----------------------------------------------------------------------
const TopPetCareMoreMenu = ({ data, handleRefresh, currentStatus }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(data);
  const [checked, setChecked] = useState(currentStatus);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("checked",checked);
    const payload = {
      _id: id,
      is_top_pet_carers: !checked,
    };
    console.log('payload', payload);
    return GrommingTopPetCare(payload).then((res) => {
      console.log('res', res);
      if (res.data.status === true) {
        // toast.success(res.data.message);
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          // showConfirmButton: false,
          button: false,
        });
        handleRefresh((preval) => {
          console.log('preval', preval);
          return !preval;
        });
        // window.location.reload();
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          // showConfirmButton: false,
          button: false, 
        });
      }
    });
  };

  return (
    <>
      {/* <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton> */}

      <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />

      {/* <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      > */}
      {/* <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify
              icon={currentStatus === true ? 'eva:trash-2-outline' : 'clarity:success-standard-solid'}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === true ? 'No' : 'Yes'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleDelete}
          />
        </MenuItem> */}

      {/* <MenuItem component={RouterLink} to="/dashboard/editBulkUpload" sx={{ color: 'text.secondary' }} >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText  primary="Edit" primaryTypographyProps={{ variant: 'body2' }} onClick={handleEdit} />
        </MenuItem> */}
      {/* </Menu> */}
    </>
  );
};

export default TopPetCareMoreMenu;
