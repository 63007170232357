/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
// components
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { TrainingBookingHistoryListHead } from '../../../sections/@dashboard/TrainingBookingHistory';
// mock
import { getTrainingBookingHistoryData } from '../../../common/api-service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  { id: 'reschedule_date', label: 'Reschedule Date', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function TrainingBookingHistory() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const bookingHistory = () => {
    const payload = page;
    return getTrainingBookingHistoryData(payload).then(
      (res) => {
        setData(res.data.booking_details);
        setFilteredData(res.data.booking_details.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    bookingHistory();
  }, [offset, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
           Training Booking History
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/trainingBookingCompleted"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Completed Booking
          </Button>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/trainingBookingOngoing"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Ongoing Booking
          </Button>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/trainingBookingUpcoming"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Upcoming Booking
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <TrainingBookingHistoryListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const {
                        _id,
                        pet_name,
                        service_name,
                        start_date,
                        time_slot,
                        parent_phone_no,
                        parent_name,
                        amount_paid,
                        add_ons,
                        package_title,
                        reschedule_date,
                        reschedule_status,
                        reschedule_time_slot,
                        sitter_name,
                        sitter_phone_no,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{parent_name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {pet_name.map((a, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {pet_name[i]}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{service_name}</TableCell>
                          <TableCell align="left">{moment(start_date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{time_slot}</TableCell>
                          <TableCell align="left">{amount_paid}</TableCell>
                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left">{package_title}</TableCell>
                          <TableCell align="left">{moment(reschedule_date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{reschedule_status}</TableCell>
                          <TableCell align="left">{reschedule_time_slot}</TableCell>
                          <TableCell align="left">{parent_phone_no}</TableCell>
                          <TableCell align="left">{sitter_name}</TableCell>
                          <TableCell align="left">{sitter_phone_no}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={data.number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
