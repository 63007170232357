import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import swal from 'sweetalert';
// component
import Iconify from '../../../components/Iconify';
import { disableTimeSlot } from '../../../common/api-service';

// ----------------------------------------------------------------------
const TimeSlotSubMoreMenu = ({ data, timeSlot1, setOffset }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(data);
  const [timeSlot, setSlot] = useState(timeSlot1);
  const [loading, setLoading] = useState(false);

  const handleDisable = () => {
    const payload = {
      _id: id,
      slot: timeSlot,
      status: 'disabled',
    };
    return disableTimeSlot(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        setOffset((preval) => !preval);
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
        setOffset((preval) => !preval);
      }
    });
  };

  const handleActive = () => {
    const payload = {
      _id: id,
      slot: timeSlot,
      status: 'active',
    };
    return disableTimeSlot(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        setOffset(!loading);
      } else {
        swal({
          title: res.data.message,
          icon: 'error',
          timer: 2000,
          button: false,
        });
        setOffset(!loading);
      }
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Active" primaryTypographyProps={{ variant: 'body2' }} onClick={handleActive} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Disable" primaryTypographyProps={{ variant: 'body2' }} onClick={handleDisable} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default TimeSlotSubMoreMenu;
