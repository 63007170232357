/* eslint-disable camelcase */
/* eslint prefer-const: "error" */
// /* eslint prefer-let: 2 */
/* eslint-env es6 */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { addFaqs } from '../../common/api-service';

function AddFaqs() {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const [baseImage, setBaseImage] = useState('');
  const [typeOfUser, setTypeOfUser] = useState('');

  const type_users = ['user', 'sitter'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(type, Name, theme) {
    return {
      fontWeight: Name.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      que: question,
      ans: answer,
      type_of_user: typeOfUser,
    };
    return addFaqs(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          navigate('/dashboard/Faqs', { replace: true });
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Faqs
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Type of User */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Types Of User</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={typeOfUser}
                        onChange={(e) => {
                          setTypeOfUser(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Types Of User" />}
                        MenuProps={MenuProps}
                      >
                        {type_users.map((type_of_user) => (
                          <MenuItem
                            key={type_of_user}
                            value={type_of_user}
                            style={getStyles(type_of_user, typeOfUser, theme)}
                          >
                            {type_of_user}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    {/* Question */}
                    <TextField
                      id="outlined-basic"
                      label="Question"
                      variant="outlined"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid>
                    {/* Answer */}
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <TextareaAutosize
                        minRows={4}
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="Answer"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Faqs
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default AddFaqs;
