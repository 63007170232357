import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import Button from '@mui/material/Button';
// component

// ----------------------------------------------------------------------
const SitterMoreMenu = ({ data, dataDate, rowData }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [id, setId] = useState(data);
  const [date, setStartDate] = useState(dataDate);
  const [calData, setCalData] = useState(rowData);

  const handleEdit = () => {
    const setid = id;
    const setcalendarData = calData;
    const setdate = date;
    localStorage.setItem('groomingCalenderData', JSON.stringify(setcalendarData));
    localStorage.setItem('getGroomingAllocationID', setid);
    localStorage.setItem('getGroomingAllocationDate', setdate);
  };

  return (
    <>
      <Button variant="contained" component={RouterLink} to="/dashboard/groomingAllocationPage" onClick={handleEdit}>
        Allocate
      </Button>
    </>
  );
};

export default SitterMoreMenu;
