/* eslint-disable camelcase */

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Collapse from '@mui/material/Collapse';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { FaqsListHead, FaqsListToolbar, FaqsMoreMenu } from '../../sections/@dashboard/faqs';
// mock
import { getFaqsData } from '../../common/api-service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Question', alignRight: false },
  { id: 'ans', label: 'Answer', alignRight: false },
  { id: 'type_of_user', label: 'User Type', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_data) => _data.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Faqs() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [collapsed, setCollapsed] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);

  const Faqs = () => {
    return getFaqsData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    Faqs();
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCollapsed = (event, _id) => {
    const selectedIndex = collapsed.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(collapsed, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(collapsed.slice(1));
    } else if (selectedIndex === collapsed.length - 1) {
      newSelected = newSelected.concat(collapsed.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(collapsed.slice(0, selectedIndex), collapsed.slice(selectedIndex + 1));
    }
    setCollapsed(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Faqs
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/addFaqs"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Faqs
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <FaqsListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, ans, que, type_of_user } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;
                      const isItemCollapsed = collapsed.indexOf(_id) !== -1;

                      return (
                        <>
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {que}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              Show Answer
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(event) => {
                                  setOpen(!open);
                                  return handleCollapsed(event, _id);
                                }}
                              >
                                {isItemCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                            <TableCell>{type_of_user}</TableCell>

                            <TableCell align="right">
                              <FaqsMoreMenu data={_id} setOffset={setOffset} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={isItemCollapsed} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography variant="h6" gutterBottom component="div">
                                    {ans}
                                  </Typography>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
