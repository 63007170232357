import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import swal from 'sweetalert';
// component
import Iconify from '../../../components/Iconify';
import { deleteTrainingSubLesson } from '../../../common/api-service';

// ----------------------------------------------------------------------
const SubLessonMoreMenu = ({ data, handleRefresh, currentStatus }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(data);

  const handleEdit = () => {
    const setid = id;
    localStorage.setItem('getSubLessonID', setid);
  };
  const handleDelete = () => {
    const payload = {
      _id: id,
      status: currentStatus === 'disabled' ? 'active' : 'disabled',
    };
    return deleteTrainingSubLesson(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        handleRefresh((preval) => {
          return !preval;
        });
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
      }
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify
              icon={currentStatus === 'disabled' ? 'clarity:success-standard-solid' : 'eva:trash-2-outline'}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === 'disabled' ? 'Active' : 'Disable'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleDelete}
          />
        </MenuItem>

        <MenuItem component={RouterLink} to="/dashboard/editTrainingSubLesson" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} onClick={handleEdit} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubLessonMoreMenu;
