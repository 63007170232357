/* eslint-disable camelcase */

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { TimeSlotListHead, TimeSlotListToolbar, TimeSlotSubMoreMenu } from '../../../sections/@dashboard/timeSlot';
// mock
import { getTimeSlots, dropDownServiceMaster, getSpecificTimeSlots } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'start_time', label: 'Start Time', alignRight: false },
  { id: 'end_time', label: 'End Time', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_data) => _data.service_master_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TimeSlotDetails() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [collapsed, setCollapsed] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [mastersData, setMastersData] = useState([]);
  const [serviceMasterId, setServiceMasterId] = useState('');

  const timeSlot = () => {
    const payload = page;
    return getTimeSlots(payload).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };
  const specificTimeSlot = (date) => {
    const payload = {
      selectedDate: date,
      pageNum: page,
    };

    return getSpecificTimeSlots(payload).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    timeSlot();
    serviceMaster();
  }, [offset, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleCollapsed = (event, _id) => {
    const selectedIndex = collapsed.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(collapsed, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(collapsed.slice(1));
    } else if (selectedIndex === collapsed.length - 1) {
      newSelected = newSelected.concat(collapsed.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(collapsed.slice(0, selectedIndex), collapsed.slice(selectedIndex + 1));
    }
    setCollapsed(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (e) => {
    setFilterName(e.target.value);
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    setFilteredData(filteredUsers);
    if (e.target.value.length === 0) {
      setFilteredData(data);
    }
  };

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setMastersData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const handleChangeDate = () => {
    const payload = page;
    return getTimeSlots(payload).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Time Slots
          </Typography>
<BackButton/>
        </Stack>
        <Card>
          <TimeSlotListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            serviceMasters={mastersData}
            setMasterDate={specificTimeSlot}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData?.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <TimeSlotListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody>
                    {filteredData?.map((row) => {
                      const { _id, time_slot, duration, start_time, end_time } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;
                      const isItemCollapsed = collapsed.indexOf(_id) !== -1;

                      return (
                        <>
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{start_time}</TableCell>
                            <TableCell align="left">{end_time}</TableCell>
                            <TableCell align="left">{duration}</TableCell>
                            <TableCell>
                              Show Details
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(event) => {
                                  setOpen(!open);
                                  return handleCollapsed(event, _id);
                                }}
                              >
                                {isItemCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={isItemCollapsed} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  {time_slot.length === 0 ? (
                                    <Typography variant="h6" gutterBottom component="div">
                                      No Slots available
                                    </Typography>
                                  ) : (
                                    <>
                                      <Typography variant="h6" gutterBottom component="div">
                                        Slots
                                      </Typography>
                                      <Table size="small" aria-label="purchases">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Slot</TableCell>
                                            <TableCell>Status</TableCell>
                                          </TableRow>
                                        </TableHead>

                                        {time_slot.map((slot) => {
                                          return (
                                            <TableRow>
                                              <TableCell>{slot.slot}</TableCell>
                                              <TableCell>{slot.status}</TableCell>
                                              <TableCell align="right">
                                                <TimeSlotSubMoreMenu
                                                  data={_id}
                                                  timeSlot1={slot.slot}
                                                  setOffset={setOffset}
                                                />
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </Table>
                                    </>
                                  )}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={data.number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
