/* eslint-disable */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Box from '@mui/material/Box';
import Scrollbar from '../../../components/Scrollbar';
import { SitterGroomingListHead, SitterGroomingMoreMenu } from '../../../sections/@dashboard/sitterAllocationGrooming';
// mock
import { getGroomingSitterAllocationData, getGroomingSitterAllocationSearchData } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';

import { DatePicker } from 'antd';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'User Name', alignRight: false },
  { id: 'grooming_book_services_id', label: 'Booking No', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'number_of_pets', label: 'Number of Pets', alignRight: false },
  { id: 'target_pet', label: 'Pet Type', alignRight: false },
  { id: 'service_name', label: 'Service', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  { id: 'reschedule_date', label: 'Reschedule Date', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  { id: 'number_of_sessions', label: 'Sessions', alignRight: false },
  { id: 'session_completed', label: 'Session Completed', alignRight: false },
  { id: 'current_session_ongoing', label: 'Session Ongoing', alignRight: false },
  { id: 'session remaining', label: 'Session Remaining', alignRight: false },
  { id: 'offer_description', label: 'Offer Description', alignRight: false },
  { id: 'offer_count', label: 'Offer Count', alignRight: false },
  { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },
  { id: 'subscription', label: 'Subscription', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function GroomingSitterAllocation() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const { RangePicker } = DatePicker;

  const sitter = () => {


    const payload = page;

    return getGroomingSitterAllocationData(payload, searchQuery, fromDate, toDate, selectedDate, rowsPerPage).then(
      (res) => {
        setData(res.data.data);


        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


  const handleSearch = () => {

    return getGroomingSitterAllocationSearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );


  };

  const [selectedDate, setSelectedDate] = useState('created_at')
  console.log("selectedDate", selectedDate);
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
    // setAllocationValue(event.target.value === 'booking_date' ? 'true' : 'false');

  };
  useEffect(() => {
    console.log("useeffectc callled-------")
    sitter();
  }, [offset, page, searchQuery, fromDate, toDate, selectedDate, rowsPerPage]);

  const handleChange = (props) => {
    if (props) {
      const [start, end] = props;
      setFromDate(start);
      setToDate(end);
    } else {
      setFromDate('');
      setToDate('');
    }
  };


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Sitter Allocation
          </Typography>
          <BackButton />
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField fullWidth label="Search user name" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                  // onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop: '-4px' },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
            // onKeyDown={handleKeyPress}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <RangePicker
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Select
                style={{ margin: '0px 0px  0px 2px', height: '34px', }}
                labelId="allocation-label"
                id="allocation"
                value={selectedDate}
                onChange={handleChangeDate}
                inputProps={{ 'aria-label': 'Select' }}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="created_at" selected>Booking Date</MenuItem>
                <MenuItem value="date">Start Date</MenuItem>
                {/* <MenuItem value="reschedule_date">Reschedule Date</MenuItem> */}

              </Select>
            </Grid>
          </Grid>


        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <SitterGroomingListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData?.map((row) => {
                      const {
                        _id,
                        user_name,
                        grooming_book_services_id,
                        pet_name,
                        number_of_pets,
                        target_pet,
                        service_name,
                        booking_date,
                        date,
                        time_slot,
                        payment_method,
                        price_paid,
                        add_ons,
                        package_title,
                        reschedule_date,
                        reschedule_status,
                        reschedule_time_slot,
                        user_contact,
                        location,
                        number_of_sessions,
                        completed_sessions,
                        ongoing_session_number,
                        remaining_sessions,
                        offer_description,
                        offer_count,
                        offer_remaining,
                        subscription,
                        service_master_id,
                        pay_on_service,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;


                      return (
                        <TableRow
                          hover
                          key={grooming_book_services_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{user_name}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{grooming_book_services_id.slice(-4)}</TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{pet_name.join(' , ')}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_pets}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{target_pet.join(' , ')}</TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: '10pt', textAlign: 'center' }} wordWrap>{service_name}</Typography>
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{booking_date ? moment(booking_date).format('DD-MM-YYYY') : booking_date}</TableCell>
                          {/* <TableCell sx={{ fontSize: '10pt' }}>{moment(date).format('YYYY-MM-DD')}</TableCell> */}
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{moment(date != null ? date : "").format('DD-MM-YYYY')}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '10pt' }} wordWrap>
                              {time_slot}
                            </Typography>
                          </TableCell>
                          {/* <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{payment_method === '' ? 'Pay after service' : payment_method}</TableCell> */}
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{price_paid}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : 'Paid'}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography sx={{ fontSize: '10pt' }} variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: '10pt', textAlign: 'center' }} wordWrap>
                              {package_title}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{reschedule_date ? moment(reschedule_date).format('DD-MM-YYYY') : reschedule_date}</TableCell> */}
                          <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{reschedule_status === "approved" || "pending" ? moment(date).format('DD-MM-YYYY') : reschedule_date}</TableCell>
                          <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{reschedule_status}</TableCell>
                          {/* <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{reschedule_time_slot}</TableCell> */}
                          <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>{reschedule_status === "approved" ? time_slot : reschedule_time_slot}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{user_contact}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_sessions}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{completed_sessions}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{ongoing_session_number}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{remaining_sessions}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_sessions === 1 ? "-" : offer_description}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_sessions === 1 ? "-" : offer_count}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_sessions === 1 ? "-" : offer_remaining}</TableCell>
                          <TableCell sx={{ fontSize: '10pt', textAlign: 'center' }}>{number_of_sessions > 1 ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="right">
                            <SitterGroomingMoreMenu
                              rowData={row}
                              data={service_master_id}
                              // dataDate={moment(date !=null?date:"2022-11-26T00:00:00.000Z").format('YYYY-MM-DD')}
                              dataDate={moment(date != null ? date : "").format('DD-MM-YYYY')}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
            component="div"
            // count={data.length}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
