/* eslint-disable camelcase */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  Grid,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import swal from 'sweetalert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import backgroundDetails from '../../Assets/backgroundDetails.png'
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';

import { CouponsListHead, CouponsListToolbar, CouponsMoreMenu } from '../../sections/@dashboard/coupons';
// mock
import { couponStatusUpdateById, getCouponsData, getPetSettingEnquiryData, } from '../../common/api-service';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'Customer Name', alignRight: false },
  { id: 'customer_contact', label: 'Customer Contact', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  // { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'customer_email', label: 'Customer Email', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot_1', label: 'Start Time Slot', alignRight: false },
  { id: 'end_date', label: 'End Date..', alignRight: false },
  // { id: 'time_slot_2', label: 'End Time Slot', alignRight: false },
  { id: 'number_of_hours/day', label: 'Number of hours/day', alignRight: false },
  
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export default function PetSitting() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [is_active, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idget, setIdget] = useState('');


  const coupons = () => {
    return getCouponsData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    PetSitting();
  }, [offset,page,rowsPerPage]);


  const PetSitting = () => {
    const payload = page;
    return getPetSettingEnquiryData(payload,rowsPerPage).then(
      (res) => {
        
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const navigate = useNavigate();
  const editpage = (_id) => {
    const masterid = _id;
    localStorage.setItem('setMasterId', masterid);
    navigate('/dashboard/edit_coupons')
  }
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const openModal = (rowData) => {
    console.log('rowData', rowData);
    setSelectedRowData(rowData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 'fit-to-content',
    bgcolor: '#e1f7f1',
    boxShadow: 10,
    borderRadius: '10px',
    border:'3px solid black',
    p: 4,
    backgroundImage: `url(${backgroundDetails})`,
  };

  const LeftModalGrid = styled(Grid)(({ theme }) => ({
    // backgroundImage: ' url("https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg")', // Replace with your actual image URL
    backgroundSize: 'cover',    
    backgroundPosition: 'center',
    height: '100%', // Adjust the height as needed
    padding: theme.spacing(2), // Add padding as needed
    color:'ActiveCaption',
    // Add other styling properties as needed
  }));

  const handleToggle = (_id, is_active) => {
    setIdget(_id);
    setIsActive(!is_active); 

    
    const payload = {
      _id: idget,
      is_active: !is_active,
      
    };
    return couponStatusUpdateById(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/coupons');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pet Sitting
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add_coupons"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Pet Sitting
          </Button> */}
        </Stack>

        <Card>
          {/* <CouponsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CouponsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredData?.map((row) => {
                    const {
                      _id,
                      service_master_id,
                      customer_name,
                      pet_name,
                      pet_type,
                      customer_email,
                      address,
                      location,
                      customer_contact,
                      start_date,
                      end_date,
                      time_slot_1,
                      time_slot_2,
                      number_of_hours,
                      no_of_hours_per_day,
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                      hover
                      key={_id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      onClick={() => openModal(row)}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, coupon_name)} />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{ fontSize: '10pt', textAlign: 'center' }}
                      >
                        <Stack direction="row" spacing={2} justifyContent="center">
                          <Typography variant="subtitle2" noWrap sx={{ textAlign: 'center' }}>
                            {customer_name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {customer_contact}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {address}
                      </TableCell>
                      <TableCell align="left">
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                              // href={`https://www.google.com/maps?q=455,566}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell>
                      {/* <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {pet_type}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {customer_email}
                      </TableCell> */}
                    
                     
                         
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {moment(start_date).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {time_slot_1}
                      </TableCell> 
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {moment(end_date).format('DD-MM-YYYY')}
                      </TableCell>
                      {/* <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'start' }}>
                        {time_slot_2}
                      </TableCell>                   */}
                      <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                        {no_of_hours_per_day}
                      </TableCell>   
                    </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <LeftModalGrid xs={6} sx={{padding:'14px'}} >
              <Typography id="modal-modal-title" variant="h4" component="h2" style={{ textAlign: 'center' }}>
              Customer Detail
            </Typography>
              <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 2 , marginLeft:'10px'}}>
              {selectedRowData.customer_name}
              <p style={{ fontSize: '15px'}}>{selectedRowData.customer_contact}</p>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 , marginLeft:'10px'}}>
              {selectedRowData && (
                <div>
                  <p>Pet Name: {selectedRowData.pet_name}</p>
                  <p>Customer E-Mail: {selectedRowData.customer_email}</p>
                 <p>Start Date: {moment(selectedRowData.start_date).format('DD-MM-YYYY')}</p>
                 <p>Start Time Slot: {selectedRowData.time_slot_1}</p>
                 <p>End Date: {moment(selectedRowData.end_date).format('DD-MM-YYYY')}</p>
                 {/* <p>End Time Slot: {selectedRowData.time_slot_2}</p> */}
                </div>
              )}
              
            </Typography>
            </LeftModalGrid>
            <Grid xs={6} sx={{padding:'14px'}} style={{borderLeft:'2px solid black'}}>
            <Typography id="modal-modal-title" variant="h4" component="h2" style={{ textAlign: 'center' }}>
              Pet Details
            </Typography>
              <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 2 , marginLeft:'60px'}}>
              {selectedRowData.pet_name}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 , marginLeft:'60px' , marginTop:'10px'}}>
              {selectedRowData && (
                <div>
                   <p>Pet Type: {selectedRowData.pet_type}</p>
                  <p>Pet Breed: {selectedRowData.pet_breed}</p>
                  <p>Pet Gender: {selectedRowData.pet_gender}</p>
                  <p>Pet DOB: {moment(selectedRowData.pet_dob).format('DD-MM-YYYY')}</p>
                  <p>Pet Weight Category: {selectedRowData.pet_weight}</p>
                  <p>Pet Vaccination: {selectedRowData.pet_vaccination=== "true" ? 'Yes':'No'}</p>
                  <p>Pet Aggression: {selectedRowData.pet_aggression}</p>
                  <p>Pet Friendly with: {selectedRowData.pet_friendliness?.map((addOn,i) => addOn).join(', ')}</p>
                </div>
              )}
            </Typography>
              </Grid>
            </Grid>
           
          </Box>
        </Modal>

      </Container>
    </Page>
  );
}
