import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import Button from '@mui/material/Button';
// component

// ----------------------------------------------------------------------
const SitterTrainingMoreMenu = ({ data, dataDate, rowData }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [id, setId] = useState(data);
  const [date, setStartDate] = useState(dataDate);
  const [calData, setCalData] = useState(rowData);

  const handleEdit = () => {
    const setTrainingId = id;
    const setTrainingCalendarData = calData;
    console.log('setTrainingCalendarData: ', setTrainingCalendarData);
    const setTrainingDate = date;
    localStorage.setItem('trainingCalenderData', JSON.stringify(setTrainingCalendarData));
    localStorage.setItem('getTrainingAllocationID', setTrainingId);
    localStorage.setItem('getTrainingAllocationDate', setTrainingDate);
  };

  return (
    <>
      <Button variant="contained" component={RouterLink} to="/dashboard/trainingAllocationPage" onClick={handleEdit}>
        Allocate
      </Button>
    </>
  );
};

export default SitterTrainingMoreMenu;
