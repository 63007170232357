/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import swal from 'sweetalert';
import Button from '@mui/material/Button';
import {
  dropDownServiceMaster,
  getGroomiingOfferingDataForUpdate,
  groomingOfferingUpdate,
} from '../../../common/api-service';

function EditOffering() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const offering_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [fieldCount, setFieldCount] = useState([]);
  const [AdminNotes, setAdminNotes] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [offerstatus, setOfferStatus] = React.useState(' ');
  const [idget, setIdget] = React.useState(' ');
  const [serviceMasterId, setServiceMaster] = React.useState([]);
  const [data, setData] = useState([]);

  const handleAdd = () => {
    const abc = [...fieldCount, []];
    setFieldCount(abc);
  };

  const handleChange = (onChangevalue, i) => {
    const inputData = [...fieldCount];
    inputData[i] = onChangevalue.target.value;
    setFieldCount(inputData);
  };

  const handleDelete = (i) => {
    const deleteVal = [...fieldCount];
    deleteVal.splice(i, 1);
    setFieldCount(deleteVal);
  };

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };
  const getGroomingOfferID = () => {
    const getGroomingOfferID = localStorage.getItem('getGroomingOfferID');
    setIdget(getGroomingOfferID);
    const payload = {
      _id: getGroomingOfferID,
    };

    return getGroomiingOfferingDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.title);
        setFieldCount(data.sub_offering);
        setAdminNotes(data.admin_notes);
        setPetName(data.target_pet);
        setServiceMaster(data.service_master_id);
        setOfferStatus(data.status);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    serviceMaster();
    getGroomingOfferID();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      _id: idget,
      title: userName,
      sub_offering: fieldCount,
      admin_notes: AdminNotes,
      target_pet: petName,
      status: offerstatus,
    };
    return groomingOfferingUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/groomingOffering', { replace: true });
        } else {
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Update Grooming Offering
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Title */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Button variant="contained" onClick={() => handleAdd()} sx={{ mb: 3 }}>
                      Add SubOffering
                    </Button>

                    {fieldCount.map((subOffering, i) => {
                      return (
                        <Grid sx={{ mt: 3 }}>
                          <TextField
                            id="outlined-basic"
                            label="SubOffering"
                            variant="outlined"
                            value={subOffering}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                            style={{ width: '100%', mt: 3 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    aria-label="Delete"
                                    edge="end"
                                    color="error"
                                    variant="outlined"
                                    onClick={() => handleDelete(i)}
                                  >
                                    Delete
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Admin Notes */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Service Master Dropdown */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Service Master</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={serviceMasterId}
                        onChange={(e) => {
                          setServiceMaster(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                        MenuProps={MenuProps}
                      >
                        {data.map((service_master_id) => (
                          <MenuItem
                            key={service_master_id._id}
                            value={service_master_id._id}
                            style={getStyles(service_master_id, serviceMasterId, theme)}
                          >
                            {service_master_id.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={offerstatus}
                        onChange={(e) => {
                          setOfferStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {offering_status.map((offering_status) => (
                          <MenuItem
                            key={offering_status}
                            value={offering_status}
                            style={getStyles(offering_status, offerstatus, theme)}
                          >
                            {offering_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Offering
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditOffering;
