import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import Iconify from "./Iconify";

export default function BackButton()
{  const navigate = useNavigate();

    return(
        <Box
        m={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={1}
      >
        <Button variant="contained" startIcon={<Iconify icon="eva:arrow-back-outline" />} onClick={ () => navigate(-1)}>
          Back
        </Button>
      </Box>
    );
}