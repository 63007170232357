// routes
import { createContext, useEffect, useState } from 'react';


import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
// import Notification from './Notification';



// ----------------------------------------------------------------------
export const ThemeContext = createContext();
// export const NotificationTokenContext = createContext();
export default function App() {
  const [sitterAllocationCount, setSitterAllocationCount] = useState("");
  const [cancellationRequestCount, setCancellationRequestCount] = useState("");
  const [rescheduleRequestCount, setRescheduleRequestCount] = useState("");

  // const [fcmMessageFirebaseToken, setFcmMessageFirebaseToken] = useState('');
  // const [notificationMsg,setNotificationMsg]=useState(false);

  const contextValues = {
    sitterAllocationCount,
    setSitterAllocationCount,
    cancellationRequestCount,
    setCancellationRequestCount,
    rescheduleRequestCount,
    setRescheduleRequestCount,
    // fcmMessageFirebaseToken,
    // setFcmMessageFirebaseToken,
    // setNotificationMsg,
    // notificationMsg
  }

  // console.log("notificationMsg",notificationMsg)

  // const requestForToken = () => {
  //   return getToken(messaging, { vapidKey: "BAuQ9ilzAdCRxcoN8G68eJuxfrEEsTy4kVKFvvccImOBs6wignZuZr7nLGLXqOOoPazTKKFvvW3253gMcHpMEMg" })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         console.log('current token for client: ', currentToken);
  //         console.log("Testing FireBase")
  //         // Perform any other neccessary action with the token
  //       } else {
  //         // Show permission request UI
  //         console.log('No registration token available. Request permission to generate one.');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //     });
  // };

  // useEffect(()=>{
  //   // requestPermission();
  //   requestForToken();
  // },[requestForToken])

  return (
    
    <ThemeContext.Provider value={contextValues}>
    
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      {/* <Notification/> */}
    </ThemeProvider>
    </ThemeContext.Provider> 
  );
}
