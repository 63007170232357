/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { BookingOngoingListHead } from '../../../sections/@dashboard/bookingOngoing';
// mock
import { getTrainingBookingOngoingData } from '../../../common/api-service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function TrainingBookingOngoing() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const bookingOngoing = () => {
    return getTrainingBookingOngoingData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    bookingOngoing();
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Booking Ongoing
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <BookingOngoingListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, pet_name, service_name, start_date, time_slot, customer_name } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                        </TableCell> */}
                          <TableCell align="left">{customer_name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* {pet_name.map((a) => ( */}
                              <Typography variant="subtitle2" noWrap>
                                {pet_name}
                              </Typography>
                              {/* ))} */}
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{service_name}</TableCell>
                          <TableCell align="left">{moment(start_date).format('DD-MM-YYYY')}</TableCell>
                          {/* // YYYY-MM-DD */}
                          <TableCell align="left">{time_slot}</TableCell>
                          {/* <TableCell align="left">{time_slot_2}</TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
