import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import swal from 'sweetalert';
import BackButton from '../../../components/BackButton';
import { dropDownServiceMaster, addTimeSlots } from '../../../common/api-service';



/* eslint-disable camelcase */
/* eslint-disable */

const Time = [
  '1:00 AM ',
  '1:30 AM',
  '2:00 AM',
  '3:00 AM',
  '3:30 AM',
  '4:30 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 AM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '5:30 PM',
  '6:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

const TimeSlot = (props) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeInterval, setTimeInterval] = useState(null);
  const [data, setData] = useState([]);
  const [timeDuration, setTimeDuration] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    serviceMaster();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleTime = (timeinterval) => {
    setTimeDuration(timeinterval);

    let tempSlots = [];
    if (timeinterval > 0) {
      setTimeInterval(timeinterval);
      let slot_s_time = moment('2000-01-01 ' + startTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
      let slot_e_time = moment('2000-01-01 ' + endTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');

      while (
        moment(slot_s_time, 'YYYY-MM-DD hh:mm A').format() < moment(slot_e_time, 'YYYY-MM-DD hh:mm A').format() ||
        moment(slot_s_time, 'YYYY-MM-DD hh:mm A').add(timeinterval, 'minutes').format() <
          moment(slot_e_time, 'YYYY-MM-DD hh:mm A').format()
      ) {
        tempSlots.push({
          slot:
            moment(slot_s_time, 'YYYY-MM-DD hh:mm A').format('hh:mm A') +
            '-' +
            moment(slot_s_time, 'YYYY-MM-DD hh:mm A').add(timeinterval, 'minutes').format('hh:mm A '),

          status: 'active',
        });
        slot_s_time = moment(slot_s_time, 'YYYY-MM-DD hh:mm A')
          .add(timeinterval, 'minutes')
          .format('YYYY-MM-DD hh:mm A');
      }
    }
    setTimeInterval(tempSlots);
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    const openTime = moment(startTime, ['HH.mm']).format('hh:mm A');

    const closeTime = moment(endTime, ['HH.mm']).format('hh:mm A');
    setLoading(true);
    const payload = {
      start_time: openTime,
      end_time: closeTime,
      time_slot: timeInterval,
      duration: timeDuration,
    };
    return addTimeSlots(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/groomingTimeSlotDetails', { replace: true });
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Card>
        <CardContent>
        <div style={{display:'flex', justifyContent:'space-between'}}>
                <Typography variant='h4'>Add Time Slot</Typography>
                <BackButton/>
                </div>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid sx={{ minWidth: '100%', mt: 3 }}>
                <TextField
                  id="time"
                  label="Start Time"
                  type="time"
                  onChange={(time) => setStartTime(time.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid sx={{ minWidth: '100%', mt: 3 }}>
                <TextField
                  id="time"
                  label="End Time"
                  type="time"
                  onChange={(time) => setEndTime(time.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid>
                <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                  <InputLabel id="demo-multiple-chip-label">Time Interval</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(time) => handleTime(time.target.value)}
                    input={<OutlinedInput id="select-multiple-chip" label="Closing Time" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={30}>30 minutes </MenuItem>
                    <MenuItem value={60}>60 minutes </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid sx={{ mt: 3 }}>
                <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                  <LoadingButton variant="contained" type="submit">
                    Add Time Slots
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default TimeSlot;
