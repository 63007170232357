/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  GroomingReschduleListHead,
  GroomingReschduleMoreMenu,
} from '../../../sections/@dashboard/groomingReschduleBooking';
// mock
import { getGroomingReschduleData, getGroomingReschduleSearchData } from '../../../common/api-service';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../../components/BackButton';

import { DatePicker } from 'antd';
import { ThemeContext } from "../../../App";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'Name', alignRight: false },
  { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  { id: 'previous_start_date', label: 'Previous Start Date', alignRight: false },
  { id: 'reschedule_request_date', label: 'Reschedule Request Date', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Request Time Slot', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'status', label: 'Action', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
// export const TotalRecordsContext = createContext();
export default function GroomingReschduleBooking() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  
  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const { RangePicker } = DatePicker;
  


  // const {setTotalNoRecord,totalNoRecord} = useContext(ThemeContext);


  const reshdule = () => {
    const payload = page;
    return getGroomingReschduleData(payload, searchQuery, fromDate, toDate).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        // setTotalNoRecord(res.data.data.total_number_of_records);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    reshdule();
  }, [offset, page, searchQuery, fromDate, toDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = () => {

    return getGroomingReschduleSearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      
      // handleSearch();
    }
  };

  const datefilterhandleChange = (props) => {
    if (props) {
      const [start, end] = props;
      setFromDate(start);
      setToDate(end);
    } else {
      setFromDate('');
      setToDate('');
    }
  };


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Grooming Reschedule Booking
          </Typography>
          <BackButton/>
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField fullWidth label="Search" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop:'-4px'},
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              onKeyDown={handleKeyPress}
            />
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <RangePicker
            onChange={datefilterhandleChange }
            />
            </Grid>
          </Grid>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <GroomingReschduleListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      {
                        console.log('filtereddata', row);
                      }

                      const {
                        _id,
                        user_name,
                        service_name,
                        pet_name,
                        pet_type,
                        date_of_booking,
                        cancellation_request_date,
                        reschedule_date,
                        amount,
                        package_name,
                        start_date,
                        user_contact_no,
                        reschedule_status,
                        reschedule_time_slot,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {user_name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{user_contact_no}</TableCell>
                          <TableCell align="left" style={{padding:'11px', width: '20px'}}>
                            <Typography>{start_date ? moment(start_date).format('DD-MM-YYYY') : start_date}</Typography>
                          </TableCell>
                          <TableCell align="left">{service_name}</TableCell>
                          <TableCell align="left">{pet_name.join(' , ')}</TableCell>
                          <TableCell align="left">{pet_type.join(' , ')}</TableCell>
                          <TableCell align="left">{package_name}</TableCell>
                          <TableCell align="center">
                            <Typography>{date_of_booking ? moment(date_of_booking).format('DD-MM-YYYY') : date_of_booking}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{reschedule_date ? moment(reschedule_date).format('DD-MM-YYYY') : cancellation_request_date}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{reschedule_time_slot}</Typography>
                          </TableCell>
                          <TableCell align="left">{amount}</TableCell>
                          <TableCell align="left">{reschedule_status}</TableCell>

                          <TableCell align="right">
                            <GroomingReschduleMoreMenu
                              data={_id}
                              handleRefresh={setOffset}
                              currentStatus={reschedule_status}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            // count={data.number_of_records}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    
    </Page>
  );
}
