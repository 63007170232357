import axios from 'axios'
import Const from './constant'


export function api() {
  const token = localStorage.getItem('token');
    // console.log('token',token);
  const instance = axios.create({
    baseURL: Const.API.Url,
    
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${token}`
    }
  });

  
  return instance;
}
