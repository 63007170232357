/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from 'react-csv';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import { bulkImport } from '../../common/api-service';

const BulkUpload = () => {
  const [docFile, setDocFile] = useState();
  const [jsonarray, setJsonarray] = useState([]);
  const [data, setData] = useState([]);

  const csvData = [
    [
      'name',
      'email',
      'phone',
      'alternate_phone',
      'age',
      'gender',
      'city',
      'covid_vaccinated',
      'address',
      'sub_area',
      'pincode',
      'occupation',
      'staying_with',
      'bank_account_beneficiary',
      'ifsc_code',
      'account_number',
      'upi_id',
      'is_petBoarding',
      'is_petGrooming',
      'is_dogWalking',
      'is_dogTraining',
      'pet_care_experience',
      'years_of_experience',
      'have_a_pet',
      'type_of_pet',
      'pets_name',
      'pet_breed',
      'pet_age',
      'pet_gender',
      'is_pet_neutered',
      'live_in',
      'property_pet_proofed',
      'balcony_for_pets',
      'terrace_for_pets',
      'garden_for_pets',
      'no_of_pets_to_board',
      'target_pet',
      'gender_of_pet_for_boarding',
      'size_of_pet_for_boarding',
      'can_handle_aggressive_pets',
      'open_to_only_one_pet',
      'board_pets_with_special_needs',
      'verification_docs_submitted',
      'finished_quiz',
      'basic_grooming',
      'basic_training',
      'pick_and_drop_service',
      'provide_non_veg_food',
      'oral_medicine_administration',
      'small_introduction',
      'leash_training',
      'poop_picking',
      'walk_dogs_at_a_time',
      'size_of_pet_for_walk',
      'walking_during_morning',
      'walking_during_afternoon',
      'walking_during_evening',
      'areas_of_specialization',
      'use_pet_friendly_equipment',
      'level_of_certification',
      'training_methods',
      'services_completed',
      'repeat_clients',
      'rating',
    ],
  ];

  const readFile = () => {
    const f = docFile;

    const name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonObeject = convertToJson(data);
    };
    reader.readAsBinaryString(f);
  };

  const convertToJson = (csv) => {
    const lines = csv.split('\n');

    const result = [];

    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = !isNaN(currentline[j]) ? parseInt(currentline[j]) : currentline[j];
      }
      result.push(obj);
    }
    let finArray = [];
    let noAdded = [];

    const jsonFormat = result;

    const final = jsonFormat.filter((jss) => {
      if (jss.email === '') {
        jss.email = null;
      }
      if (
        jss.name == undefined ||
        jss.name == '' ||
        jss.email == undefined ||
        jss.email == '' ||
        jss.phone == undefined ||
        jss.phone == '' ||
        jss.alternate_phone == undefined ||
        jss.alternate_phone == '' ||
        jss.age == undefined ||
        jss.age == '' ||
        jss.gender == undefined ||
        jss.gender == '' ||
        jss.city == undefined ||
        jss.city == '' ||
        jss.covid_vaccinated == undefined ||
        jss.covid_vaccinated == '' ||
        jss.address == undefined ||
        jss.address == '' ||
        jss.sub_area == undefined ||
        jss.sub_area == '' ||
        jss.pincode == undefined ||
        jss.pincode == '' ||
        jss.occupation == undefined ||
        jss.occupation == '' ||
        jss.staying_with == undefined ||
        jss.staying_with == '' ||
        jss.bank_account_beneficiary == undefined ||
        jss.bank_account_beneficiary == '' ||
        jss.ifsc_code == undefined ||
        jss.ifsc_code == '' ||
        jss.account_number == undefined ||
        jss.account_number == '' ||
        jss.upi_id == undefined ||
        jss.upi_id == '' ||
        jss.is_petBoarding == undefined ||
        jss.is_petBoarding == '' ||
        jss.is_petGrooming == undefined ||
        jss.is_petGrooming == '' ||
        jss.is_dogWalking == undefined ||
        jss.is_dogWalking == '' ||
        jss.is_dogTraining == undefined ||
        jss.is_dogTraining == '' ||
        jss.pet_care_experience == undefined ||
        jss.pet_care_experience == '' ||
        jss.years_of_experience == undefined ||
        jss.years_of_experience == '' ||
        jss.have_a_pet == undefined ||
        jss.have_a_pet == '' ||
        jss.type_of_pet == undefined ||
        jss.type_of_pet == '' ||
        jss.pets_name == undefined ||
        jss.pets_name == '' ||
        jss.pet_breed == undefined ||
        jss.pet_breed == '' ||
        jss.pet_age == undefined ||
        jss.pet_age == '' ||
        jss.pet_gender == undefined ||
        jss.pet_gender == '' ||
        jss.is_pet_neutered == undefined ||
        jss.is_pet_neutered == '' ||
        jss.live_in == undefined ||
        jss.live_in == '' ||
        jss.property_pet_proofed == undefined ||
        jss.property_pet_proofed == '' ||
        jss.balcony_for_pets == undefined ||
        jss.balcony_for_pets == '' ||
        jss.terrace_for_pets == undefined ||
        jss.terrace_for_pets == '' ||
        jss.garden_for_pets == undefined ||
        jss.garden_for_pets == '' ||
        jss.no_of_pets_to_board == undefined ||
        jss.no_of_pets_to_board == '' ||
        jss.target_pet == undefined ||
        jss.target_pet == '' ||
        jss.gender_of_pet_for_boarding == undefined ||
        jss.gender_of_pet_for_boarding == '' ||
        jss.size_of_pet_for_boarding == undefined ||
        jss.size_of_pet_for_boarding == '' ||
        jss.can_handle_aggressive_pets == undefined ||
        jss.can_handle_aggressive_pets == '' ||
        jss.open_to_only_one_pet == undefined ||
        jss.open_to_only_one_pet == '' ||
        jss.board_pets_with_special_needs == undefined ||
        jss.board_pets_with_special_needs == '' ||
        jss.verification_docs_submitted == undefined ||
        jss.verification_docs_submitted == '' ||
        jss.finished_quiz == undefined ||
        jss.finished_quiz == '' ||
        jss.basic_grooming == undefined ||
        jss.basic_grooming == '' ||
        jss.basic_training == undefined ||
        jss.basic_training == '' ||
        jss.pick_and_drop_service == undefined ||
        jss.pick_and_drop_service == '' ||
        jss.provide_non_veg_food == undefined ||
        jss.provide_non_veg_food == '' ||
        jss.oral_medicine_administration == undefined ||
        jss.oral_medicine_administration == '' ||
        jss.small_introduction == undefined ||
        jss.small_introduction == '' ||
        jss.leash_training == undefined ||
        jss.leash_training == '' ||
        jss.poop_picking == undefined ||
        jss.poop_picking == '' ||
        jss.walk_dogs_at_a_time == undefined ||
        jss.walk_dogs_at_a_time == '' ||
        jss.size_of_pet_for_walk == undefined ||
        jss.size_of_pet_for_walk == '' ||
        jss.walking_during_morning == undefined ||
        jss.walking_during_morning == '' ||
        jss.walking_during_afternoon == undefined ||
        jss.walking_during_afternoon == '' ||
        jss.walking_during_evening == undefined ||
        jss.walking_during_evening == '' ||
        jss.areas_of_specialization == undefined ||
        jss.areas_of_specialization == '' ||
        jss.use_pet_friendly_equipment == undefined ||
        jss.use_pet_friendly_equipment == '' ||
        jss.level_of_certification == undefined ||
        jss.level_of_certification == '' ||
        jss.training_methods == undefined ||
        jss.training_methods == '' ||
        jss.services_completed == undefined ||
        jss.services_completed == '' ||
        jss.repeat_clients == undefined ||
        jss.repeat_clients == '' ||
        jss.rating == undefined ||
        jss.rating == ''
      ) {
        console.log(undefined);
        noAdded.push(jss);
      } else {
        if (jss.type != null) {
          jss.type = jss.type.toLowerCase();
        }
        finArray.push(jss);
      }
    });
    setJsonarray(finArray);

    return jsonFormat; // JSON
  };

  const handleSubmit = async () => {
    if (jsonarray === '') {
      console.log('err');
    } else {
      const payload = {
        jsonarray,
      };
      return bulkImport(payload).then(
        (res) => {
          if (res.data.status === true) {
            console.log(res.data);
            swal({
              title: res.data.message,
              icon: 'success',
              timer: 2000,
              button: false,
            });
          } else {
            swal({
              title: res.data.message,
              icon: 'danger',
              timer: 2000,
              button: false,
            });
          }
        },
        (err) => {
          console.log('err', err);
        }
      );
    }
  };

  // Import CSV BULK
  const filePathset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    setDocFile(file);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  return (
    <div>
      <Card
        sx={{
          width: '100%',
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '30px',
        }}
        spacing={3}
      >
        <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
          Upload Sitter
        </Typography>
        <Divider />
        <CardContent sapcing={3}>
          <Grid container align="center" direction="column" justify="center" spacing={0}>
            <Grid item>
              <Grid>
                <input
                  className="btn btn-primary btn-round submitbtn mr-3"
                  type="file"
                  id="file"
                  accept=".csv , .xlsx"
                  onChange={filePathset}
                />
                <Button variant="contained" component="label" onClick={readFile}>
                  Bulk Import
                </Button>
              </Grid>

              <Grid item>
                <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                  <Grid>
                    <CSVLink data={csvData}>
                      <Button variant="contained" component="label">
                        Download Sample
                      </Button>
                    </CSVLink>
                  </Grid>

                  <Grid>
                    <Button variant="contained" component="label" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default BulkUpload;
