/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import swal from 'sweetalert';
import { getBannerDataForUpdate, bannerUpdate } from '../../common/api-service';

function EditBanner() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [idget, setIdget] = React.useState(' ');
  const [data, setData] = useState([]);
  const [typeOfUser, setTypeOfUser] = useState('');
  const [baseImage, setBaseImage] = useState('');

  const getBannerID = () => {
    const getBannerID = localStorage.getItem('getBannerID');
    setIdget(getBannerID);
    const payload = {
      _id: getBannerID,
    };
    return getBannerDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.name);
        setBaseImage(data.icon);
        setTypeOfUser(data.type_of_user);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    getBannerID();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      _id: idget,
      name: userName,
      icon: baseImage,
      type_of_user: typeOfUser,
    };
    return bannerUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/banner', { replace: true });
          localStorage.removeItem('getBannerID');
        } else {
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Update Banner
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Name */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Image upload */}
                  <Grid>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography component="div" display="flex" justifyContent="center" alignItems="center">
                        Icon
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <br />
                      <img src={baseImage} alt="" height="200px" />
                    </Stack>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Banner
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditBanner;
