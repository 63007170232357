// component

import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Service Master',
    path: '/dashboard/serviceMaster',
    icon: getIcon('eva:pie-chart-2-fill'),
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Pet Grooming',
    children: [
      {
        title: 'Pet Grooming Packages',
        path: '/dashboard/petGrooming',
      },
      {
        title: 'Sitter Allocations',
        path: '/dashboard/groomingSitterAllocation',
      },
      {
        title: 'Grooming Time Slot',
        path: '/dashboard/groomingTimeSlotDetails',
      },
      {
        title: 'Grooming Booking History',
        path: '/dashboard/groomingBookingHistory',
      },
      {
        title: 'Cancellation Requests',
        path: '/dashboard/groomingCancellation',
      },
      {
        title: 'Cancellation History',
        path: '/dashboard/groomingCancellationHistory',
      },
      {
        title: 'Reschedule Request',
        path: '/dashboard/groomingReschduleBooking',
      },
      {
        title: 'Reschedule History',
        path: '/dashboard/groomingReschduleHistory',
      },
      {
        title: 'Subscription',
        path: '/dashboard/groomingSubscription',
      },
    ],
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Dog Training',
    children: [
      {
        title: 'Dog Training Packages',
        path: '/dashboard/dogTraining',
      },

      {
        title: 'Training Time Slot',
        path: '/dashboard/trainingTimeSlotDetails',
      },
      {
        title: 'Sitter Allocation',
        path: '/dashboard/trainingSitterAllocation',
      },
      {
        title: "Training Booking History",
        path: '/dashboard/trainingBookingHistory',
      },
      {
        title: "Cancellation Request",
        path: '/dashboard/trainingCancellation',
      },
      {
        title: "Cancellation History",
        path: '/dashboard/trainingCancellationHistory',
      },
      {
        title: "Reschedule Booking",
        path: '/dashboard/trainingReschduleBooking',
      },
      {
        title: "Reschedule History",
        path: '/dashboard/trainingReschduleHistory',
      },
      {
        title: 'Lesson',
        path: '/dashboard/trainingLesson',
      },
      {
        title: 'Sub Lesson',
        path: '/dashboard/trainingSubLesson',
      },
    ],
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Sitter',
    children: [
      {
        title: 'Sitter List',
        path: '/dashboard/bulk_upload_details',
      },
      {
        title: 'Upload Sitter',
        path: '/dashboard/bulk_upload',
      },
    ],
  },
  {
    icon: getIcon('mdi:ticket-percent'),
    title: 'Coupons',
    children: [
      {
        title: 'Add Coupons',
        path: '/dashboard/add_coupons',
      },
      {
        title: 'Coupons',
        path: '/dashboard/coupons',
      },
    ],
  },
  {
    icon: getIcon('mdi:message-outline'),
    title: 'Enquiry',
    children: [
      {
        title: 'Pet Boarding',
        path: '/dashboard/petboarding',
      },
      {
        title: 'Dog Walking',
        path: '/dashboard/dogwalking',
      },
      {
        title: 'Pet Sitting',
        path: '/dashboard/petsetting',
      },
      {
        title: 'Dog Training',
        path: '/dashboard/dogtrainings',
      },
    ],
  },
  {
    icon: getIcon('clarity:notification-solid'),
    title: 'Notifications',
    children: [
      {
        title: 'Add Notification',
        path: '/dashboard/notification',
      },
      {
        title: 'Notification List',
        path: '/dashboard/notificationlist',
      },
    ],
  },
  {
    title: 'Blog Management',
    path: '/dashboard/blogManagement',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Banner Management',
    path: '/dashboard/banner',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Request Module',
    path: '/dashboard/request',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Pet List',
    path: '/dashboard/petList',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'User Management',
    path: '/dashboard/userManagement',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Faqs',
    path: '/dashboard/faqs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Top Pet Care',
    path: '/dashboard/topPetCare',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Contact Us (Customer)',
    path: '/dashboard/contactUs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Contact Us (Sitter)',
    path: '/dashboard/sitterContactUs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
];

export default navConfig;
