/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import swal from 'sweetalert';
import { dropDownServiceMaster, getBulkUploadDataForUpdate, bulkUploadUpdate } from '../../common/api-service';

function EditBulkUpload() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];

  function getStyles(type, Name, theme) {
    return {
      fontWeight: Name.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
      //  theme.typography.fontWeightRegular,
    };
  }

  const [sitterPhone, setSitterPhone] = useState('');
  const [sitterEmail, setSitterEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [sitterAge, setSitterAge] = React.useState([]);
  const [sitterGender, setSitterGender] = React.useState('');
  const [sitterAlterPhone, setSitterAlterPhone] = React.useState([]);
  const [sitterCovid, setSitterCovid] = React.useState([]);
  const [sitterAddress, setSitterAddress] = React.useState([]);

  const [sitterArea, setSitterArea] = React.useState([]);
  const [sitterPinCode, setSitterPinCode] = React.useState([]);
  const [sitterOccupation, setSitterOccupation] = React.useState([]);
  const [sitterStay, setSitterStay] = React.useState([]);
  const [sitterBenificiary, setSitterBenificiary] = React.useState([]);

  const [sitterIfsc, setSitterIfsc] = React.useState([]);
  const [sitterAccountNo, setSitterAccountNo] = React.useState([]);
  const [sitterUpi, setSitterUpi] = React.useState([]);
  const [sitterServices, setSitterServices] = React.useState([]);

  const [sitterCareExperiance, setSitterCareExperiance] = React.useState([]);
  const [sitterYearExperience, setSitterYearExperience] = React.useState([]);
  const [sitterHavePet, setSitterHavePet] = React.useState([]);
  const [sitterTypePet, setSitterTypePet] = React.useState([]);
  const [sitterPetName, setSitterPetName] = React.useState([]);

  const [sitterBreed, setSitterBreed] = React.useState([]);
  const [sitterPetAge, setSitterPetAge] = React.useState([]);
  const [sitterPetGender, setSitterPetGender] = React.useState([]);
  const [sitterPetGenderBoarding, setSitterPetGenderBoarding] = React.useState([]);
  const [sitterPetNeutered, setSitterPetNeutered] = React.useState([]);

  const [sitterLinveIn, setSitterLinveIn] = React.useState([]);
  const [sitterPetProofed, setSitterPetProofed] = React.useState([]);
  const [sitterBalconyPet, setSitterBalconyPet] = React.useState([]);
  const [sitterTerracePet, setSitterTerracePet] = React.useState([]);
  const [sitterGardenPet, setSitterGardenPet] = React.useState([]);

  const [sitterNumPetBoard, setSitterNumPetBoard] = React.useState([]);
  const [sitterTargetPet, setSitterTargetPet] = React.useState([]);
  const [sitterSizePetBoarding, setSitterSizePetBoarding] = React.useState([]);
  const [sitterAggersivePet, setSitterAggersivePet] = React.useState([]);

  const [sitterOnlyOnePet, setSitterOnlyOnePet] = React.useState([]);
  const [sitterSpecialNeed, setSitterSpecialNeed] = React.useState([]);
  const [sitterDocSubmitted, setSitterDocSubmitted] = React.useState([]);
  const [sitterQuiz, setSitterQuiz] = React.useState([]);
  const [sitterBasicGrooming, setSitterBasicGrooming] = React.useState([]);

  const [sitterBasicTraining, setSitterBasicTraining] = React.useState([]);
  const [sitterPickDrop, setSitterPickDrop] = React.useState([]);
  const [sitterNonVegFood, setSitterNonVegFood] = React.useState([]);
  const [sitterOralMedicine, setSitterOralMedicine] = React.useState([]);
  const [sitterSmallIntro, setSitterSmallIntro] = React.useState([]);

  const [sitterLeashTraining, setSitterLeashTraining] = React.useState([]);
  const [sitterPoopPick, setSitterPoopPick] = React.useState([]);
  const [sitterWalkDog, setSitterWalkDog] = React.useState([]);
  const [sitterSizePetWalk, setSitterSizePetWalk] = React.useState([]);
  const [sitterWalkMorning, setSitterWalkMorning] = React.useState([]);

  const [sitterWalkAfterNoon, setSitterWalkAfterNoon] = React.useState('');
  const [idget, setIdget] = React.useState('');
  const [sitterWalkEvening, setSitterWalkEvening] = React.useState([]);
  const [sitterAreaSpcialization, setSitterAreaSpcialization] = React.useState([]);
  const [sitterEquipment, setSitterEquipment] = React.useState([]);
  const [sitterCertificate, setSitterCertificate] = React.useState([]);
  const [sitterTraningMethod, setSitterTraningMethod] = React.useState([]);
  const [sitterServiceCompleted, setSitterServiceCompleted] = React.useState([]);
  const [sitterRepeatClient, setSitterRepeatClient] = React.useState([]);
  const [sitterRating, setSitterRating] = React.useState([]);
  const [sitterCity, setSitterCity] = React.useState('');

  const [data, setData] = useState([]);

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };
  const getBulkID = () => {
    const getBulkID = localStorage.getItem('getBulkID');
    setIdget(getBulkID);
    const payload = {
      sitter_id: getBulkID,
    };
    return getBulkUploadDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.name);
        setSitterPhone(data.phone);
        setSitterEmail(data.email);
        setSitterAge(data.age);
        setSitterGender(data.gender);
        setSitterAlterPhone(data.alternate_phone);
        setSitterCovid(data.covid_vaccinated);
        setSitterAddress(data.address);
        setSitterArea(data.sub_area);
        setSitterPinCode(data.pincode);
        setSitterOccupation(data.occupation);
        setSitterStay(data.staying_with);
        setSitterBenificiary(data.bank_account_beneficiary);
        setSitterIfsc(data.ifsc_code);
        setSitterAccountNo(data.account_number);
        setSitterUpi(data.upi_id);
        setSitterServices(data.services);
        setSitterCareExperiance(data.pet_care_experience);
        setSitterYearExperience(data.years_of_experience);
        setSitterHavePet(data.have_a_pet);
        setSitterTypePet(data.type_of_pet);
        setSitterPetName(data.pets_name);
        setSitterBreed(data.pet_breed);
        setSitterPetAge(data.pet_age);
        setSitterPetGender(data.pet_gender);
        setSitterPetNeutered(data.is_pet_neutered);
        setSitterLinveIn(data.live_in);
        setSitterPetProofed(data.property_pet_proofed);
        setSitterBalconyPet(data.balcony_for_pets);
        setSitterTerracePet(data.terrace_for_pets);
        setSitterGardenPet(data.garden_for_pets);
        setSitterNumPetBoard(data.no_of_pets_to_board);
        setSitterTargetPet(data.target_pet);
        setSitterPetGenderBoarding(data.gender_of_pet_for_boarding);
        setSitterSizePetBoarding(data.size_of_pet_for_boarding);
        setSitterAggersivePet(data.can_handle_aggressive_pets);
        setSitterOnlyOnePet(data.open_to_only_one_pet);
        setSitterSpecialNeed(data.board_pets_with_special_needs);
        setSitterDocSubmitted(data.verification_docs_submitted);
        setSitterQuiz(data.finished_quiz);
        setSitterBasicGrooming(data.basic_grooming);
        setSitterBasicTraining(data.basic_training);
        setSitterPickDrop(data.pick_and_drop_service);
        setSitterNonVegFood(data.provide_non_veg_food);
        setSitterOralMedicine(data.oral_medicine_administration);
        setSitterSmallIntro(data.small_introduction);
        setSitterLeashTraining(data.leash_training);
        setSitterPoopPick(data.poop_picking);
        setSitterWalkDog(data.walk_dogs_at_a_time);
        setSitterSizePetWalk(data.size_of_pet_for_walk);
        setSitterWalkMorning(data.walking_during_morning);
        setSitterWalkAfterNoon(data.walking_during_afternoon);
        setSitterWalkEvening(data.walking_during_evening);
        setSitterAreaSpcialization(data.areas_of_specialization);
        setSitterEquipment(data.use_pet_friendly_equipment);
        setSitterCertificate(data.level_of_certification);
        setSitterTraningMethod(data.training_methods);
        setSitterServiceCompleted(data.services_completed);
        setSitterRepeatClient(data.repeat_clients);
        setSitterRating(data.rating);
        setSitterCity(data.city);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    serviceMaster();
    getBulkID();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      _id: idget,
      name: userName,
      email: sitterEmail,
      phone: sitterPhone,
      alternate_phone: sitterAlterPhone,
      city: sitterCity,
      age: sitterAge,
      gender: sitterGender,
      covid_vaccinated: sitterCovid,
      address: sitterAddress,
      sub_area: sitterArea,
      pincode: sitterPinCode,
      occupation: sitterOccupation,
      staying_with: sitterStay,
      bank_account_beneficiary: sitterBenificiary,
      ifsc_code: sitterIfsc,
      account_number: sitterAccountNo,
      upi_id: sitterUpi,
      services: sitterServices,
      pet_care_experience: sitterCareExperiance,
      years_of_experience: sitterYearExperience,
      have_a_pet: sitterHavePet,
      type_of_pet: sitterTypePet,
      pets_name: sitterPetName,
      pet_breed: sitterBreed,
      pet_age: sitterPetAge,
      pet_gender: sitterPetGender,
      is_pet_neutered: sitterPetNeutered,
      live_in: sitterLinveIn,
      property_pet_proofed: sitterPetProofed,
      balcony_for_pets: sitterBalconyPet,
      terrace_for_pets: sitterTerracePet,
      garden_for_pets: sitterGardenPet,
      no_of_pets_to_board: sitterNumPetBoard,
      target_pet: sitterTargetPet,
      gender_of_pet_for_boarding: sitterPetGenderBoarding,
      size_of_pet_for_boarding: sitterSizePetBoarding,
      can_handle_aggressive_pets: sitterAggersivePet,
      open_to_only_one_pet: sitterOnlyOnePet,
      board_pets_with_special_needs: sitterSpecialNeed,
      verification_docs_submitted: sitterDocSubmitted,
      finished_quiz: sitterQuiz,
      basic_grooming: sitterBasicGrooming,
      basic_training: sitterBasicTraining,
      pick_and_drop_service: sitterPickDrop,
      provide_non_veg_food: sitterNonVegFood,
      oral_medicine_administration: sitterOralMedicine,
      small_introduction: sitterSmallIntro,
      leash_training: sitterLeashTraining,
      poop_picking: sitterPoopPick,
      walk_dogs_at_a_time: sitterWalkDog,
      size_of_pet_for_walk: sitterSizePetWalk,
      walking_during_morning: sitterWalkMorning,
      walking_during_afternoon: sitterWalkAfterNoon,
      walking_during_evening: sitterWalkEvening,
      areas_of_specialization: sitterAreaSpcialization,
      use_pet_friendly_equipment: sitterEquipment,
      level_of_certification: sitterCertificate,
      training_methods: sitterTraningMethod,
      services_completed: sitterServiceCompleted,
      repeat_clients: sitterRepeatClient,
      rating: sitterRating,
    };

    return bulkUploadUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/bulk_upload_details', { replace: true });
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        swal({
          title: err,
          icon: 'error',
          timer: 2000,
          button: false,
        });
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Update Sitter
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Name */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Email */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={sitterEmail}
                      onChange={(e) => setSitterEmail(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Gender */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Gender"
                      variant="outlined"
                      value={sitterGender}
                      onChange={(e) => setSitterGender(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* City */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      value={sitterCity}
                      onChange={(e) => setSitterCity(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Covid Vaccinated */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Covid Vaccinated"
                      variant="outlined"
                      value={sitterCovid}
                      onChange={(e) => setSitterCovid(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Phone Number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      value={sitterPhone}
                      onChange={(e) => setSitterPhone(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Alternate Phone Number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Alternate Phone"
                      variant="outlined"
                      value={sitterAlterPhone}
                      onChange={(e) => setSitterAlterPhone(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Age */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Age"
                      variant="outlined"
                      value={sitterAge}
                      onChange={(e) => setSitterAge(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Address */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Address"
                      variant="outlined"
                      value={sitterAddress}
                      onChange={(e) => setSitterAddress(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* sub_area */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Sub  Area"
                      variant="outlined"
                      value={sitterArea}
                      onChange={(e) => setSitterArea(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Pincode */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pincode"
                      variant="outlined"
                      value={sitterPinCode}
                      onChange={(e) => setSitterPinCode(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Occupation */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Occupation"
                      variant="outlined"
                      value={sitterOccupation}
                      onChange={(e) => setSitterOccupation(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* live In */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Live In"
                      variant="outlined"
                      value={sitterLinveIn}
                      onChange={(e) => setSitterLinveIn(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* staying_with */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Staying With"
                      variant="outlined"
                      value={sitterStay}
                      onChange={(e) => setSitterStay(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Target Pet"
                      variant="outlined"
                      value={sitterTargetPet}
                      onChange={(e) => setSitterTargetPet(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* bank_account_beneficiary */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Bank Account Beneficiary"
                      variant="outlined"
                      value={sitterBenificiary}
                      onChange={(e) => setSitterBenificiary(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Ifsc Code */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Ifsc Code"
                      variant="outlined"
                      value={sitterIfsc}
                      onChange={(e) => setSitterIfsc(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* account_number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Account Number"
                      variant="outlined"
                      value={sitterAccountNo}
                      onChange={(e) => setSitterAccountNo(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* upi_id */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="UPI Id"
                      variant="outlined"
                      value={sitterUpi}
                      onChange={(e) => setSitterUpi(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Service Master Dropdown */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Service Master</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={sitterServices}
                        onChange={(e) => {
                          setSitterServices(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                        MenuProps={MenuProps}
                      >
                        {data.map((service_master_id) => (
                          <MenuItem
                            key={service_master_id._id}
                            value={service_master_id._id}
                            style={getStyles(service_master_id, sitterServices, theme)}
                          >
                            {service_master_id.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Type"
                      variant="outlined"
                      value={sitterTypePet}
                      onChange={(e) => setSitterTypePet(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* pet_care_experience */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Care Experience"
                      variant="outlined"
                      value={sitterCareExperiance}
                      onChange={(e) => setSitterCareExperiance(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* years_of_experience */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Year Of Experience"
                      variant="outlined"
                      value={sitterYearExperience}
                      onChange={(e) => setSitterYearExperience(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* pets_name */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet name"
                      variant="outlined"
                      value={sitterPetName}
                      onChange={(e) => setSitterPetName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* pet_breed */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Breed"
                      variant="outlined"
                      value={sitterBreed}
                      onChange={(e) => setSitterBreed(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* pet_age */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Age"
                      variant="outlined"
                      value={sitterPetAge}
                      onChange={(e) => setSitterPetAge(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* pet_gender */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Gender"
                      variant="outlined"
                      value={sitterPetGender}
                      onChange={(e) => setSitterPetGender(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* no_of_pets_to_board */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="No Of Pets To Board"
                      variant="outlined"
                      value={sitterNumPetBoard}
                      onChange={(e) => setSitterNumPetBoard(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* board_with_special_need */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Board with  special need"
                      variant="outlined"
                      value={sitterSpecialNeed}
                      onChange={(e) => setSitterSpecialNeed(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* gender_of_pet_for_boarding */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Gender Of Pet For Boarding"
                      variant="outlined"
                      value={sitterPetGenderBoarding}
                      onChange={(e) => setSitterPetGenderBoarding(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* size_of_pet_for_boarding */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Size of pet for Boarding"
                      variant="outlined"
                      value={sitterSizePetBoarding}
                      onChange={(e) => setSitterSizePetBoarding(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* walk_dogs_at_a_time */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Walk Dogs at a time"
                      variant="outlined"
                      value={sitterWalkDog}
                      onChange={(e) => setSitterWalkDog(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* size_of_pet_for_walk */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Size of pet for walk"
                      variant="outlined"
                      value={sitterSizePetWalk}
                      onChange={(e) => setSitterSizePetWalk(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Areas of Specialization */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Areas of Specialization"
                      variant="outlined"
                      value={sitterAreaSpcialization}
                      onChange={(e) => setSitterAreaSpcialization(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Training Methods */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Training Methods"
                      variant="outlined"
                      value={sitterTraningMethod}
                      onChange={(e) => setSitterTraningMethod(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Small Introduction */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Small Introductio"
                      variant="outlined"
                      value={sitterSmallIntro}
                      onChange={(e) => setSitterSmallIntro(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Level of Certification */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Level of Certification"
                      variant="outlined"
                      value={sitterCertificate}
                      onChange={(e) => setSitterCertificate(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Service Completed */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Service Completed"
                      variant="outlined"
                      value={sitterServiceCompleted}
                      onChange={(e) => setSitterServiceCompleted(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* have_a_pet */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-simple-select">Have a Pet</InputLabel>
                      <Select
                        labelId="demo-simple-select"
                        id="demo-simple-select"
                        // multiple
                        value={sitterHavePet}
                        onChange={(e) => {
                          setSitterHavePet(e.target.value);
                        }}
                        input={<OutlinedInput id="demo-simple-select" label="Have a Pet" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* is_pet_neutered */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">is_Pet Neutered</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPetNeutered}
                        label="is_Pet Neutered"
                        onChange={(e) => {
                          setSitterPetNeutered(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* property_pet_proofed */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Property Pet Proofed</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPetProofed}
                        label="Property Pet Proofed"
                        onChange={(e) => {
                          setSitterPetProofed(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* balcony_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Balcony for Pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBalconyPet}
                        label="Balcony for Pets"
                        onChange={(e) => {
                          setSitterBalconyPet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* terrace_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Terrace for pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterTerracePet}
                        label="Terrace for pets"
                        onChange={(e) => {
                          setSitterTerracePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* garden_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Garden for pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterGardenPet}
                        label="Garden for pets"
                        onChange={(e) => {
                          setSitterGardenPet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* can_handle_aggressive_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Handle Aggressive Pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterAggersivePet}
                        label="Handle Aggressive Pets"
                        onChange={(e) => {
                          setSitterAggersivePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* open_to_only_one_pet */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Open to only one pet</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterOnlyOnePet}
                        label="Open to only one pet"
                        onChange={(e) => {
                          setSitterOnlyOnePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* verification_docs_submitted */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Verification docs submitted</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterDocSubmitted}
                        label="Verification docs submitted"
                        onChange={(e) => {
                          setSitterDocSubmitted(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* finished_quiz */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Finished quiz</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterQuiz}
                        label="Finished quiz"
                        onChange={(e) => {
                          setSitterQuiz(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* basic_grooming */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Basic Grooming</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBasicGrooming}
                        label="Basic Grooming"
                        onChange={(e) => {
                          setSitterBasicGrooming(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* basic_training */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Basic Traning</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBasicTraining}
                        label="Basic Traning"
                        onChange={(e) => {
                          setSitterBasicTraining(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* pick_and_drop_service */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Pick and Drop Service</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPickDrop}
                        label="Pick and Drop Service"
                        onChange={(e) => {
                          setSitterPickDrop(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* provide_non_veg_food */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Provid non veg food</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterNonVegFood}
                        label="Provid non veg food"
                        onChange={(e) => {
                          setSitterNonVegFood(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* oral_medicine_administration */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Oral medicine administration</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterOralMedicine}
                        label="Oral medicine administration"
                        onChange={(e) => {
                          setSitterOralMedicine(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* leash_training */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Leash Training</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterLeashTraining}
                        label="Leash Training"
                        onChange={(e) => {
                          setSitterLeashTraining(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* poop_picking */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Poop Picking</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPoopPick}
                        label="Leash Training"
                        onChange={(e) => {
                          setSitterPoopPick(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* walking_during_morning */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during morning</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkMorning}
                        label="Walking during morning"
                        onChange={(e) => {
                          setSitterWalkMorning(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* walking_during_afternoon */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during afternoon</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkAfterNoon}
                        label="Walking during afternoon"
                        onChange={(e) => {
                          setSitterWalkAfterNoon(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* walking_during_evening */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during Evening</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkEvening}
                        label="Walking during Evening"
                        onChange={(e) => {
                          setSitterWalkEvening(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* use_pet_friendly_equipment */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Use pet friendly equipment</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterEquipment}
                        label="Use pet friendly equipment"
                        onChange={(e) => {
                          setSitterEquipment(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Repeat Clients */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Repeat Clients"
                      variant="outlined"
                      value={sitterRepeatClient}
                      onChange={(e) => setSitterRepeatClient(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Rating */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Rating"
                      variant="outlined"
                      value={sitterRating}
                      onChange={(e) => setSitterRating(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Sitter
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditBulkUpload;
