/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  InputAdornment,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@mui/material/Box';
import { DatePicker } from 'antd';
import * as XLSX from 'xlsx';
import { styled } from '@mui/system';
import ExcelLogo from '../../../Assets/ExcelLogo.jpg';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { BookingOngoingListHead } from '../../../sections/@dashboard/bookingOngoing';
// mock
import { getGroomingBookingOngoingData , getGroomingBookingOngoingSearchData } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'no_of_pets', label: 'number of Pets', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'price_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  // { id: 'package_title', label: 'Packages', alignRight: false },
  // { id: 'reschedule_date', label: 'Reschedule Date', alignRight: false },
  // { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  // { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  { id: 'user_phone_no', label: 'Contact Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  { id: 'sessions', label: 'Number of Session', alignRight: false },
  { id: 'completed_sessions', label: 'Completed Sessions', alignRight: false },
  { id: 'current_session_ongoing', label: 'Ongoing Session', alignRight: false },
  { id: 'remaining_sessions', label: 'Remaining Session', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function BookingOngoing() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [searchQuery,setSearchQuery]=useState("");

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;

  const datefilterhandleChange = (props) => {
    if (props) {
      const [start, end] = props;
      setFromDate(start);
      setToDate(end);
    } else {
      setFromDate('');
      setToDate('');
    }
  };

  const bookingOngoing = () => {
    const payload=page;
    return getGroomingBookingOngoingData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState('created_at');
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    bookingOngoing();
  }, [offset,page,searchQuery,rowsPerPage,fromDate,toDate,selectedDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  // const handleSearch = () => {
  //   return getGroomingBookingOngoingSearchData().then(
  //     (res) => {
  //       setData(res.data.data);
  //       setFilteredData(res.data.data);
  //       // setFilteredData(res.data.data.get_paginated);

  //     },
  //     (err) => {
  //       console.log('err data: ', err);
  //     }
  //   );

  // };

  const formatDataForExport = () => {
    return filteredData.map((row) => [
      // row._id,
      row.customer_name,
      row.pet_name,
      row.pet_type,
      row.number_of_pets,
      row.service_name,
      moment(row.booking_date).format('DD-MM-YYYY'),
      moment(row.date).format('DD-MM-YYYY'),
      row.time_slot,
      // row.payment_method === '' ? 'Pay after service' : 'Online',
      row.pay_on_service === true  ? 'Pay after service' : "Online",
      row.price_paid,
      // row.payment_method === '' ? 'Not Paid' : 'Paid',
      row.payment_method === '' ? 'Not Paid' : `Paid${row.pay_on_service ? ` (${row.payment_method})` : ''}`,
      // row.add_ons,
      row.add_ons.map((addOn) => addOn.title).join(', '),
      row.package_title,
      row.user_phone_no,
      row.address,
      row.sitter_name,
      row.sitter_phone,
      row.no_of_sessions === 1 || row.no_of_sessions === 0 ? 'Single' : 'Subscription',
      row.no_of_sessions,
      row.completed_sessions,
      row.ongoing_session_number,
      row.remaining_sessions,
      // Add other columns based on your TABLE_HEAD structure
    ]);
  };


  const handleExportToExcel = () => {
    const formattedData = formatDataForExport();
    const ws = XLSX.utils.aoa_to_sheet([TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GroomingBookingOngoing');
    XLSX.writeFile(wb, 'GroomingBookingOngoing.xlsx');
  };

  const ExcelButton = styled(Button)({
    backgroundColor: '#217346', // Excel logo color
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#217346', // Hover color
    },
  });


  const handleKeyPress = (e) => {
    // if (e.key === 'Enter') {
      
    //   handleSearch();
    // }
  };
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Booking Ongoing
          </Typography>
          <BackButton/>
        </Stack>

        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center" sx={{ display: 'flex' }}>
        <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
        <Grid item xs={8} sm={8} md={8} lg={8} style={{ alignContent: 'right', paddingTop: '5px' }}>
              <RangePicker onChange={datefilterhandleChange} />

              <Select
                style={{ margin: '0px 0px  0px 2px', height: '34px' }}
                labelId="allocation-label"
                id="allocation"
                value={selectedDate}
                onChange={handleChangeDate}
              >
                <MenuItem value="All" disabled>
                  Select
                </MenuItem>
                <MenuItem value="created_at" selected>
                  Booking Date
                </MenuItem>
                <MenuItem value="date">Start Date</MenuItem>
              </Select>
            </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField fullWidth label="Search user name" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                    // onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop:'-4px'},
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              onKeyDown={handleKeyPress}
            />
          </Grid>
          </Grid>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <BookingOngoingListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const {
                        _id,
                         pet_name,
                         pet_type,
                         number_of_pets,
                         service_name,
                         date,
                         time_slot,
                         customer_name,
                         payment_method,
                         price_paid,
                         add_ons,
                         package_title,
                         user_phone_no,
                         location,
                         sitter_name,
                         sitter_phone,
                         no_of_sessions,
                         booking_date,
                         completed_sessions,
                         ongoing_session_number,
                         remaining_sessions,
                         pay_on_service,
                       } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                        </TableCell> */}
                           <TableCell align="left">{customer_name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {pet_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{pet_type}</TableCell>
                          <TableCell align="left">{number_of_pets}</TableCell>
                          <TableCell align="left">{service_name}</TableCell>
                          <TableCell align="left">{moment(booking_date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{moment(date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{time_slot}</TableCell>

                          {/* <TableCell align="left">{payment_method === '' ? 'Pay after service' : payment_method}</TableCell> */}
                          <TableCell align="left">{pay_on_service === true  ? 'Pay after service' : "Online"}</TableCell>
                          <TableCell align="left">{price_paid}</TableCell>
                          {/* <TableCell align="left">{payment_method === '' ? 'Not Paid' : 'Paid'}</TableCell> */}
                          <TableCell align="left">{payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell> 
                          <TableCell align="left">{package_title}</TableCell>      
                          <TableCell align="left">{user_phone_no}</TableCell>
                          <TableCell align="left">
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location.coordinates[0]},${location.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell>
                          <TableCell align="left">{sitter_name}</TableCell>
                          <TableCell align="left">{sitter_phone}</TableCell>
                          <TableCell align="center">
                            {no_of_sessions === 1 || no_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell align="center">{no_of_sessions}</TableCell>
                          <TableCell align="center">{completed_sessions}</TableCell>
                          <TableCell align="center">{ongoing_session_number}</TableCell>
                          <TableCell align="center">{remaining_sessions}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            // count={data.length}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <div style={{ textAlign: 'end', marginTop: '4%' }}>
          <ExcelButton variant="contained" onClick={handleExportToExcel}>
            <img
              // src="https://wallpapers.com/images/hd/excel-microsoft-2013-application-logo-oo7x8r2rnik4ohlo.jpg"
              src={ExcelLogo}
              alt="Export To Excel"
              height={28}
              width={40}
            />
            <Typography>Export to Excel</Typography>
          </ExcelButton>
        </div>


      </Container>
    </Page>
  );
}
