/* eslint-disable */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Box from '@mui/material/Box';
import Scrollbar from '../../../components/Scrollbar';
import { SitterTrainingListHead, SitterTrainingMoreMenu } from '../../../sections/@dashboard/trainingSitterAllocation';
// mock
import { getTrainingSitterAllocationData } from '../../../common/api-service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'User Name', alignRight: false },
  { id: 'training_book_services_id', label: 'Booking No', alignRight: false },
  { id: 'service_name', label: 'Service', alignRight: false },
  { id: 'date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'number_of_pets', label: 'Number of Pets', alignRight: false },
  { id: 'number_of_sessions', label: 'Sessions', alignRight: false },
  { id: 'package_title', label: 'Package', alignRight: false },
  { id: 'target_pet', label: 'Pet Type', alignRight: false },
  { id: 'subscription', label: 'Subscription', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function TrainingSitterAllocation() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const sitter = () => {
    return getTrainingSitterAllocationData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    sitter();
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Sitter Allocation
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {console.log("filteredData",filteredData)}
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <SitterTrainingListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        _id,
                        training_book_services_id,
                        service_master_id,
                        service_name,
                        time_slot,
                        date,
                        number_of_pets,
                        number_of_sessions,
                        package_title,
                        target_pet,
                        subscription,
                        user_name,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={training_book_services_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" sx={{ fontSize: '10pt' }}>
                            {user_name}
                          </TableCell>
                          <TableCell>{training_book_services_id.slice(-4)}</TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: '10pt' }} wordWrap>
                              {service_name}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{moment(date).format('YYYY-MM-DD')}</TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: '10pt' }} wordWrap>
                              {time_slot}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{number_of_pets}</TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{number_of_sessions}</TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: '10pt' }} wordWrap>
                              {package_title}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{target_pet}</TableCell>
                          <TableCell sx={{ fontSize: '10pt' }}>{subscription === true ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="right">
                            <SitterTrainingMoreMenu
                              rowData={row}
                              data={service_master_id}
                              dataDate={moment(date).format('YYYY-MM-DD')}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
