/* eslint-disable camelcase */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import swal from 'sweetalert';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';

import { CouponsListHead, CouponsListToolbar, CouponsMoreMenu } from '../../sections/@dashboard/coupons';
// mock
import { couponStatusUpdateById, getCouponsData } from '../../common/api-service';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'coupon_name', label: 'Coupon Name', alignRight: false },
  { id: 'coupon_code', label: 'Coupon Code', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'discount', label: 'Coupon Discount', alignRight: false },
  { id: 'discount_type', label: 'Discount Type', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'validity', label: 'Validity in Days', alignRight: false },
  { id: 'minimum_amount', label: 'Minimum Purchace', alignRight: false },
  { id: 'max_times_to_be_availed', label: 'User Limit', alignRight: false },
  { id: 'valid_from', label: 'Start Date', alignRight: false },
  { id: 'valid_till', label: 'End Date..', alignRight: false },
  { id: 'is_active', label: 'Is Active', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export default function Coupons() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idget, setIdget] = useState('');


  const coupons = () => {
    return getCouponsData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    coupons();
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const navigate = useNavigate();
  const editpage = (_id) => {
    const masterid = _id;
    localStorage.setItem('setMasterId', masterid);
    navigate('/dashboard/edit_coupons')
  }

  const handleToggle = (id, isactive) => {
    // console.log('id',id);
    // console.log('isactive',isactive);
    setLoading(true);
    // setIdget(id);
    // const isActiveStatus = isactive; 
    // setSwitchState(isActiveStatus);

    
    const payload = {
      _id: id,
      is_active: !isactive,
      
    };

    console.log(payload);
    return couponStatusUpdateById(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          setFilteredData((prevData) =>
            prevData.map((row) =>
              row._id === id ? { ...row, is_active: !isactive } : row
            )
          );

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/coupons');
        } else {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
          
        }
        
      },
      (err) => {
        console.log('err', err);
      }
    );
  };


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Coupons
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add_coupons"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Coupons
          </Button>
        </Stack>

        <Card>
          {/* <CouponsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CouponsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredData.map((row) => {
                    const {
                      _id,
                      coupon_name,
                      coupon_code,
                      pet_type,
                      discount,
                      description,
                      discount_type,
                      validity_days,
                      minimum_amount,
                      max_times_to_be_availed,
                      valid_from,
                      valid_till,
                      is_active,
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, coupon_name)} />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          sx={{ fontSize: '10pt', textAlign: 'center' }}
                        >
                          <Stack direction="row" spacing={2} justifyContent="center">
                            <Typography variant="subtitle2" noWrap sx={{ textAlign: 'center' }}>
                              {coupon_name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {coupon_code}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {pet_type}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {discount}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {discount_type}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {description}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {validity_days} Days
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {minimum_amount}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {max_times_to_be_availed}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {moment(valid_from).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          {moment(valid_till).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          <Switch
                            checked={is_active}
                            // onChange={() =>handleToggle(_id,is_active) }
                            onChange={() => handleToggle(_id, is_active)}
                            color="primary"
                            sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: 'green' } }}
                          />
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: '10pt', textAlign: 'center' }}>
                          <IconButton color="primary" onClick={() => editpage(_id)}>
                            <EditIcon />
                          </IconButton>
                          {/* <IconButton color="error">
                            <DeleteIcon />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
