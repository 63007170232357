/* eslint-disable */
/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import {
  dropDownServiceMaster,
  dropDownOffering,
  dropDownLesson,
  getGroomingPackagesDataForUpdate,
  groomingPackagesUpdate,
  getAddonsDropDownData,
} from '../../common/api-service';
import BackButton from '../../components/BackButton';

function EditPetGrooming() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const packages_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  function getStyles1(pet_type, petName, theme) {
    return {
      fontWeight: theme.typography.fontWeightMedium,
      // petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [packagefieldCount, setPackageFieldCount] = useState([]);
  const [packageAddOnsfieldCount, setPackageAddOnsfieldCount] = useState([]);
  const [packageStatus, setPackageStatus] = React.useState('');
  const [idget, setIdget] = React.useState('');
  const [AdminNotes, setAdminNotes] = useState('');
  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [offeringId, setOfferingID] = React.useState([]);
  const [serviceMasterId, setServiceMaster] = React.useState([]);
  const [offeringdata, setOfferData] = React.useState([]);
  const [addOnsdata, setAddOnsData] = React.useState([]);
  const [packagePrice, setPackagePrice] = React.useState('');
  const [NumberOfPets, setNumberOfPets] = React.useState('');
  const [validityDay, setValidityDay] = React.useState(999);
  const [checked, setChecked] = React.useState(true);
  const [packageLessonfieldCount, setPackageLessonFieldCount] = useState([]);
  const [offerPrice, setOfferPrice] = React.useState('');
  const [numberOfSession, setNumberOfSession] = React.useState('');
  const [subscriptionSession, setSubscriptionSessions] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [subs, setSubs] = React.useState([]);

  // const [sessionValue, setSessionValue] =useState();
  // console.log("sessionValue",sessionValue);
  // console.log("subs...", subs);

  const handleFieldChange = (index, field, value) => {
    // console.log(", index ", index + ", field ",field + ", value ", value)
    const updatedSubs = [...subs]; // Create a shallow copy of the subs array
    // console.log("updatedSubs", updatedSubs)
    updatedSubs[index] = {
      ...updatedSubs[index], // Copy the existing object
      [field]: value, // Update the specific field
    };
    setSubs(updatedSubs); // Update the state with the new array
    // setSessionValue(subs.subscription_sessions)

    const price = parseFloat(updatedSubs[index].subscription_price);
    const sessions = parseInt(updatedSubs[index].subscription_sessions) || 1;
    const offerPrice = price / sessions;
    updatedSubs[index].subscription_offer_price = offerPrice.toFixed(0);
    setSubs(updatedSubs);
  };

  const handleFieldChange1 = (index, field, value) => {
    // console.log(", index ", index + ", field ",field + ", value ", value)
    const updatedSubs = [...subs]; // Create a shallow copy of the subs array
    // console.log("updatedSubs", updatedSubs)
    updatedSubs[index] = {
      ...updatedSubs[index], // Copy the existing object
      [field]: value.replace(/\D/g, ''), // Update the specific field
    };
    setSubs(updatedSubs); // Update the state with the new array
    // setSessionValue(subs.subscription_sessions)

    const price = parseFloat(updatedSubs[index].subscription_price);
    const sessions = parseInt(updatedSubs[index].subscription_sessions) || 1;
    const offerPrice = price / sessions;
    updatedSubs[index].subscription_offer_price = offerPrice.toFixed(0);
    setSubs(updatedSubs);
  };

  const handleAdd = () => {
    const abc = [...packagefieldCount, []];
    setPackageFieldCount(abc);
  };

  const addNewhandleChange = (onChangevalue, i) => {
    const inputData = [...packagefieldCount];
    inputData[i] = onChangevalue.target.value;
    setPackageFieldCount(inputData);
  };

  const handleDelete = (i) => {
    const deleteVal = [...packagefieldCount];
    deleteVal.splice(i, 1);
    setPackageFieldCount(deleteVal);
  };

  const addNewhandleChangeAddOns = (onChangevalue, i) => {
    const inputData = [...packageAddOnsfieldCount];
    inputData[i] = onChangevalue.target.value;
    setPackageAddOnsfieldCount(inputData);
  };

  const handleDeleteAddOns = (i) => {
    const deleteVal = [...packageAddOnsfieldCount];
    deleteVal.splice(i, 1);
    setPackageAddOnsfieldCount(deleteVal);
  };

  // const handleAddSession = () => {
  //   const abc = [...subscriptionSession, []];
  //   setSubscriptionSessions(abc);
  // };

  // const handleAddSession = () => {

  //   const newSession = {
  //     subscription_sessions: "",
  //     subscription_price: "",
  //     subscription_offer_price: "",
  //     subscription_description: "",
  //   };
  //   setSubs((prevSubs) => [...prevSubs, newSession]);
  // }

  // const addNewhandleChangeSession = (onChangevalue, i) => {
  //   const inputData = [...subscriptionSession];
  //   inputData[i] = onChangevalue.target.value;
  //   setSubscriptionSessions(inputData);
  // };

  // const addNewhandleChange1 = (e, i, fieldName) => {
  //   const updatedSessions = [...subs];
  //   updatedSessions[i][fieldName] = e.target.value;
  //   setSubs(updatedSessions);
  //   // console.log("setSubs", subs)

  // };

  // const handleDeleteSession = (i) => {
  //   const deleteVal = [...subscriptionSession];
  //   deleteVal.splice(i, 1);
  //   setSubscriptionSessions(deleteVal);
  // };

  // const handleDeleteSession = (i) => {
  //   const deleteVal = [...subs];
  //   deleteVal.splice(i, 1);
  //   setSubs(deleteVal);
  // };

  // const handleChange = () => {
  //   setChecked(!checked);
  // };

  const offeringDropDown = (payload) => {
    return dropDownOffering(payload).then(
      (res) => {
        var offerDropdownData = res.data.data;
        setOfferData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const getGroomingPackageID = () => {
    const getGroomingPackageID = localStorage.getItem('getGroomingPackageID');
    setIdget(getGroomingPackageID);
    const payload = {
      _id: getGroomingPackageID,
    };
    return getGroomingPackagesDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.title);
        setNumberOfPets(data.number_of_pets);
        setPetName(data.target_pet);
        setAdminNotes(data.admin_notes);
        setPackagePrice(data.price);
        setOfferPrice(data.offer_price);
        setValidityDay(data.validity_in_days);
        setPackageFieldCount(data.description);
        setPackageStatus(data.status);
        setSubs(data.subscription_sessions_for_grooming);
        const addOnsTitle = data.add_ons;
        const addOnsTitle1 = addOnsTitle.map((e) => {
          e = e.title;
          return e;
        });
        setPackageAddOnsfieldCount(addOnsTitle1);

        const offertitle = data.offerings;

        const offertitle1 = offertitle.map((e) => {
          e = e.title;
          return e;
        });
        setOfferingID(offertitle1);
        setPetName(data.target_pet);
        setServiceMaster(data.service_master_id);
        setNumberOfPets(data.number_of_pets);
        setNumberOfSession(data.number_of_sessions);
        setChecked(data.subscription);
        setSubscriptionSessions(data.subscription_sessions_for_grooming);
      } else {
        console.log('error');
      }
    });
  };

  const addonsDropDown = (payload) => {
    return getAddonsDropDownData(payload).then(
      (res) => {
        setAddOnsData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    addonsDropDown();
    getGroomingPackageID();
    offeringDropDown();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    var array3 = offeringdata.filter(function (obj) {
      return offeringId.indexOf(obj.title) !== -1;
    });

    var array5 = addOnsdata.filter(function (obj) {
      return packageAddOnsfieldCount.indexOf(obj.title) !== -1;
    });

    const payload = {
      _id: idget,
      offerings: array3,
      service_master_id: serviceMasterId,
      add_ons: array5,
      title: userName,
      description: packagefieldCount,
      admin_notes: AdminNotes,
      target_pet: petName,
      price: packagePrice === '' ? 0 : packagePrice,
      number_of_pets: NumberOfPets === '' ? 0 : NumberOfPets,
      validity_in_days: validityDay === '' ? 0 : validityDay,
      offer_price: offerPrice === '' ? 0 : offerPrice,
      number_of_sessions: numberOfSession === '' ? 0 : numberOfSession,
      subscription: checked,
      // subscription_sessions_for_grooming: subscriptionSession === '' ? 0 : subscriptionSession,
      status: packageStatus,
      subscription_sessions_for_grooming: subs,
    };
    return groomingPackagesUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/petGrooming');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Grid>
        <Typography
          variant="h3"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Edit Pet Grooming
        </Typography>
        <BackButton />
      </Grid>
      <Card>
        <CardContent>
          <Box
            component="form"
            sx={{
              m: 5,
            }}
            minHeight="50vh"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12}>
                  {/* Title */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Number of Session */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Session"
                      variant="outlined"
                      value={numberOfSession}
                      onChange={(e) => setNumberOfSession(e.target.value)}
                      style={{ width: '100%' }}
                      aria-readonly
                      disabled
                    />
                  </Grid>

                  {/* Number of Pets */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Pets"
                      variant="outlined"
                      value={NumberOfPets}
                      onChange={(e) => setNumberOfPets(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Description */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Button variant="contained" onClick={() => handleAdd()} sx={{ mb: 3 }}>
                      Add Description
                    </Button>

                    {packagefieldCount.map((description, i) => {
                      return (
                        <Grid sx={{ mt: 3 }}>
                          <TextField
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            value={description}
                            //   value={subOffering}
                            onChange={(e) => {
                              addNewhandleChange(e, i);
                            }}
                            //   onChange={(e)=> {setSubOffering(e.target.value)}}
                            style={{ width: '100%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    aria-label="Delete"
                                    edge="end"
                                    color="error"
                                    variant="outlined"
                                    onClick={() => handleDelete(i)}
                                  >
                                    Delete
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Admin Notes */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextareaAutosize
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      minRows={3}
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Offering DropDown */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Offering</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={offeringId}
                        onChange={(e) => {
                          setOfferingID(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Offering" />}
                        MenuProps={MenuProps}
                      >
                        {offeringdata.map((e) => (
                          <MenuItem key={e._id} value={e.title} style={getStyles(e, offeringId, theme)}>
                            {e.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      value={packagePrice}
                      onChange={(e) => setPackagePrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Offer Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Offer Price"
                      variant="outlined"
                      value={offerPrice}
                      onChange={(e) => setOfferPrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Validity */}
                  {/* <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Validity"
                      variant="outlined"
                      value={validityDay}
                      onChange={(e) => setValidityDay(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid> */}

                  <Grid sx={{ mt: 3 }}>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Add Ons</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={packageAddOnsfieldCount}
                        onChange={(e) => {
                          // addNewhandleChangeAddOns(e, i);
                          setPackageAddOnsfieldCount(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Add Ons" />}
                        MenuProps={MenuProps}
                      >
                        {addOnsdata.map((e) => {
                          return (
                            <MenuItem key={e._id} value={e.title} style={getStyles(e, packageAddOnsfieldCount, theme)}>
                              {e.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {/* </Grid>
                      );
                    })} */}
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={packageStatus}
                        onChange={(e) => {
                          setPackageStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {packages_status.map((packages_status) => (
                          <MenuItem
                            key={packages_status}
                            value={packages_status}
                            style={getStyles(packages_status, packageStatus, theme)}
                          >
                            {packages_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Subscription */}
                  <Grid sx={{ mt: 3 }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        '& > :not(style)': { width: '25ch' },
                      }}
                      display="flex"
                      justifyContent="left"
                      alignItems="left"
                    >
                      Subscriptions
                    </Typography>
                  </Grid>

                  {subs.map((data, index) => (
                    <Grid
                      key={index}
                      container
                      columnSpacing={2}
                      justifyContent="center"
                      alignItems="flex-start"
                      sx={{ mt: 2 }}
                    >
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          label="No of sessions"
                          variant="outlined"
                          value={data.subscription_sessions}
                          onChange={(e) =>
                            handleFieldChange1(index, 'subscription_sessions', e.target.value.replace(/\D/g, ''))
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          label="Price"
                          variant="outlined"
                          value={data.subscription_price}
                          onChange={(e) =>
                            handleFieldChange(index, 'subscription_price', e.target.value.replace(/\D/g, ''))
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Price per session"
                          variant="outlined"
                          value={data.subscription_offer_price}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">/ session</InputAdornment>,
                          }}
                          readOnly
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          label="Offer Description"
                          variant="outlined"
                          value={data.subscription_description}
                          onChange={(e) => handleFieldChange(index, 'subscription_description', e.target.value)}
                          fullWidth
                        />
                        <Typography sx={{ color: 'red' , opacity:'0.9' }}>* Note: Please do not enter Offer Count/digit</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                        <TextField
                          id="outlined-basic"
                          // placeholder="Offers count"
                          label="Offer count"
                          variant="outlined"
                          value={data.offer_count}
                          onChange={(e) => handleFieldChange(index, 'offer_count', e.target.value)}
                          // onChange={(e) => {
                          //   setSubs({ ...subs, offer_count: e.target.value });
                          // }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ))}

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Pet Grooming
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditPetGrooming;
