export default {
    API: {
     
      //  Url: 'http://65.0.6.253/dev', 
      Url: 'http://65.0.6.253/', 
      // Url: 'http://13.127.190.39'
      
    },
    ROLE: {
      SYS_ADMIN: 0
    },
  };